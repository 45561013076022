//Irá implementar um componente de Tabela utilizando o Material UI
//Poderão utilizar: sort, pagination, filter
//O componente deverá ser genérico, ou seja, poderá ser utilizado em qualquer tela
//O componente deverá ser responsivo
//O componente deverá ser configurável, ou seja, deverá receber as propriedades e renderizar de acordo com as mesmas
import React, { useState } from "react";
import { InputLabel, Select, MenuItem, IconButton, Checkbox, Divider, Chip, Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField } from "@mui/material";
import PropTypes from "prop-types";

const Datatable = ({
    data,
    dataTotalCount,
    columns,
    onRowClick,
    sort,
    onSort,
    pagination,
    paginationPerPage,
    paginationRowsPerPageOptions,
    paginationComponentOptions,
    onPagination,
    filter,
    filterPlaceholder,
    onFilter,
    selectableRows,
    onSelect,
    actions,
    searchParams,
}) => {

    //Utilizado para controlar os parametros envolvidos na busca de dados
    let initialParams = {
        filter: columns.filter(column => column.filterable).map((column) => { return {label: column.field, value: '', type: column.dataType || 'text', filterValues: column.filterValues || null}}),
        pagination: {
            page: searchParams.pagination?.page || 0,
            perPage: searchParams.pagination?.perPage || paginationPerPage,
        },
        sort: {
            field: searchParams.sort?.field || '',
            order: searchParams.sort?.order || 'asc',
        },
    };
    if(searchParams.filter){
        initialParams = searchParams;
    }
    const [searchOptions, setSearchOptions] = useState(initialParams);

    //Variaveis utilizadas na paginação
    const [rowsPerPage, setRowsPerPage] = useState(searchParams.pagination?.perPage || paginationPerPage);

    const handleChangePage = (event, newPage) => {
        setSearchOptions({
            ...searchOptions,
            pagination: {
                ...searchOptions.pagination,
                page: newPage,
            },
        });
        onPagination({
            ...searchOptions,
            pagination: {
                ...searchOptions.pagination,
                page: newPage,
            },
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setSearchOptions({
            ...searchOptions,
            pagination: {
                ...searchOptions.pagination,
                page: 0,
                perPage: parseInt(event.target.value, 10),
            },
        });
        onPagination({
            ...searchOptions,
            pagination: {
                ...searchOptions.pagination,
                page: 0,
                perPage: parseInt(event.target.value, 10),
            },
        });
    };

    //Variaveis utilizadas no sort
    const handleSort = (field) => {
        let order = 'asc';
        if (searchOptions.sort.field === field && searchOptions.sort.order === 'asc') {
            order = 'desc';
        }
        setSearchOptions({
            ...searchOptions,
            sort: {
                field,
                order,
            },
        });
        onSort({
            ...searchOptions,
            sort: {
                field,
                order,
            },
        });
    };

    //Variaveis utilizadas no filter
    const handleFilter = (field, value) => {
        const filter = searchOptions.filter.map((item) => {
            if (item.label === field) {
                item.value = value;
            }
            return item;
        });
        setSearchOptions({
            ...searchOptions,
            filter,
        });
    };

    const handleFilterSubmit = () => {
        onFilter(searchOptions);
    };

    //Variaveis para controlar a seleção de linhas
    const [selectedRows, setSelectedRows] = useState([]);

    const handleSelectRow = (row) => {
        if (selectedRows.includes(row)) {
            setSelectedRows(selectedRows.filter((item) => item !== row));
            onSelect(selectedRows.filter((item) => item !== row));
        } else {
            setSelectedRows([...selectedRows, row]);
            onSelect([...selectedRows, row]);
        }
    };

    const handleSelectAllRows = () => {
        if (selectedRows.length === data.length) {
            setSelectedRows([]);
            onSelect([]);
        } else {
            setSelectedRows(data);
            onSelect(data);
        }
    };

    return (
        <Box>
            {filter && (
                <Box mb={2}>
                    <Divider sx={ {marginBottom: '15px', marginTop: '10px'} } ><Chip label="Filtros"/></Divider>
                    <Grid padding='15px' justifyContent='center' alignContent='center' display='flex' container spacing={2}>
                        {searchOptions.filter.map((item, index) => (
                            <Grid item key={index}>
                                {item.type === 'text' && (
                                    <TextField
                                        label={item.label}
                                        variant="outlined"
                                        size="small"
                                        value={item.value}
                                        onChange={(event) => handleFilter(item.label, event.target.value)}
                                    />
                                )}

                                {item.type === 'date' && (
                                    <TextField
                                        label={item.label}
                                        variant="outlined"
                                        size="small"
                                        value={item.value}
                                        onChange={(event) => handleFilter(item.label, event.target.value)}
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}

                                {item.type === 'number' && (
                                    <TextField
                                    type="number"
                                    label={item.label}
                                    value={item.value}
                                    onChange={(event) => handleFilter(item.label, event.target.value)}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />

                                )}

                                {item.type === 'boolean' && (
                                    <Box>
                                        <InputLabel>{item.label}</InputLabel>
                                        <Select
                                            value={item.value}
                                            onChange={(event) => handleFilter(item.label, event.target.value)}
                                        >
                                            <MenuItem value=""></MenuItem>
                                            <MenuItem value="sim">Sim</MenuItem>
                                            <MenuItem value="não">Não</MenuItem>
                                        </Select>
                                    </Box>
                                )}

                                {item.type === 'filterValues' && (
                                    <Box>
                                        <InputLabel>{item.label}</InputLabel>
                                        <Select
                                            label={item.label}
                                            value={item.value}
                                            onChange={(event) => handleFilter(item.label, event.target.value)}
                                        >
                                            {item.filterValues.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                )}
                            </Grid>
                        ))}
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleFilterSubmit}>
                                Filtrar
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider sx={ {marginBottom: '15px', marginTop: '10px'} }/>
                </Box>
            )}
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {selectableRows && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={selectedRows.length > 0 && selectedRows.length < data.length}
                                        checked={selectedRows.length === data.length}
                                        onChange={handleSelectAllRows}
                                    />
                                </TableCell>
                            )}
                            {columns.map((column, index) => (
                                <TableCell key={index}>
                                    {sort && column.sortable && (
                                        <TableSortLabel
                                            active={searchOptions.sort.field === column.field}
                                            direction={searchOptions.sort.order}
                                            onClick={() => handleSort(column.field)}
                                        >
                                            {column.headerName}
                                        </TableSortLabel>
                                    )}
                                    {(!sort || !column.sortable) && column.headerName}
                                </TableCell>
                            ))}
                            {actions.length > 0 && <TableCell>Ações</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow
                                key={index}
                                hover
                                onClick={() => onRowClick(row)}
                            >
                                {selectableRows && (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedRows.includes(row)}
                                            onChange={() => handleSelectRow(row)}
                                        />
                                    </TableCell>
                                )}
                                {columns.map((column, index) => (
                                    <TableCell key={index}>
                                        {column.renderCell ? column.renderCell(row) : column.valueFormatter ? column.valueFormatter(row) : row[column.field]}
                                    </TableCell>
                                ))}
                                {actions.length > 0 && (
                                    <TableCell>
                                        <Grid container spacing={1}>
                                            {actions.map((action, index) => (
                                                <Grid item key={index}>
                                                    <IconButton aria-label={action.name} onClick={() => action.onClick(row)}>
                                                        {action.icon}
                                                    </IconButton>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {pagination && (
                <TablePagination
                    rowsPerPageOptions={paginationRowsPerPageOptions}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    component="div"
                    count={dataTotalCount}
                    rowsPerPage={rowsPerPage}
                    page={searchOptions.pagination.page}
                    onPageChange={handleChangePage}
                    labelRowsPerPage={paginationComponentOptions.rowsPerPageText}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${paginationComponentOptions.rangeSeparatorText} ${count}`}
                />
            )}
        </Box>
    );
};

Datatable.propTypes = {
    data: PropTypes.array.isRequired,
    dataTotalCount: PropTypes.number,
    columns: PropTypes.array.isRequired,
    onRowClick: PropTypes.func,
    sort: PropTypes.bool,
    onSort: PropTypes.func,
    pagination: PropTypes.bool,
    paginationPerPage: PropTypes.number,
    paginationRowsPerPageOptions: PropTypes.array,
    paginationComponentOptions: PropTypes.object,
    onPagination: PropTypes.func,
    filter: PropTypes.bool,
    filterPlaceholder: PropTypes.string,
    onFilter: PropTypes.func,
    selectableRows: PropTypes.bool,
    onSelect: PropTypes.func,
    actions: PropTypes.array,
    searchParams: PropTypes.object,
};

Datatable.defaultProps = {
    data: [],
    dataTotalCount: 0,
    columns: [],
    onRowClick: () => { },
    sort: false,
    onSort: () => { },
    pagination: false,
    paginationPerPage: 10,
    paginationRowsPerPageOptions: [10, 20, 50],
    paginationComponentOptions: {
        rowsPerPageText: 'Linhas por página:',
        rangeSeparatorText: 'de',
        noRowsPerPage: false,
        selectAllRowsItem: false,
    },
    onPagination: () => { },
    filter: false,
    filterPlaceholder: '',
    onFilter: () => { },
    selectableRows: false,
    onSelect: () => { },
    actions: [],
    searchParams: {},
};

export default Datatable;