import { useEffect, useState } from "react";
import Datatable from "../components/Datatable";
import Header from "../components/Header";
import { Fab, Dialog, DialogTitle, DialogContent, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, DialogActions, Button, Container, Alert, LinearProgress, Box, Typography, Snackbar } from "@mui/material";
import ClientesService from "../services/ClientesService";
import EditMensagemLembrete from "../components/Clientes/EditMensagemLembrete";
import formatCurrency from "../utils/formatCurrency";
import ClientesCobranca from "../components/Clientes/ClientesCobranca";

const Clientes = () => {

    const [clientes, setClientes] = useState([]);
    const [totalClientes, setTotalClientes] = useState(0);
    const [searchParams, setSearchParams] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [openClienteContasEmAtraso, setOpenClienteContasEmAtraso] = useState(false);
    const [clienteContasEmAtraso, setClienteContasEmAtraso] = useState([]);

    const [openClienteContasEmAberto, setOpenClienteContasEmAberto] = useState(false);
    const [clienteContasEmAberto, setClienteContasEmAberto] = useState([]);

    const [openEditMensagemLembrete, setOpenEditMensagemLembrete] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleOpenEditMensagemLembrete = (row) => {
        setSelectedRow(row);
        setOpenEditMensagemLembrete(true);
    };
    
    const handleCloseEditMensagemLembrete = () => {
        setOpenEditMensagemLembrete(false);
    };

    const handleSaveMensagemLembrete = (novaMensagem, novoStatus) => {
        // Aqui você deve enviar a nova mensagem para o servidor e atualizar o estado local
        // Exemplo fictício:
        const postChangeMensagemLembrete = async () => {
            try {
                const response = await ClientesService().setLembrete(selectedRow, novaMensagem, novoStatus);
                const data = response.data;
                setClientes((prevClientes) =>
                    prevClientes.map((cliente) =>
                        cliente.id === data.cliente.id
                        ? { ...cliente, mensagemLembrete: novaMensagem, lembrete: novoStatus }
                        : cliente
                    )
                );

            } catch (error) {
                setError(true);
                setErrorMessage("Ocorreu um erro interno ao modificar a mensagem, tente novamente ou entre em contato com o suporte.");
            }
        };
        postChangeMensagemLembrete();
    };
    

    const handleOpenClienteContasEmAtraso = (contas) => {
        setClienteContasEmAtraso(contas);
        setOpenClienteContasEmAtraso(true);
        console.log(contas);
    }

    const handleCloseClienteContasEmAtraso = () => {
        setOpenClienteContasEmAtraso(false);
    }
    
    const handleOpenClienteContasEmAberto = (contas) => {
        setClienteContasEmAberto(contas);
        setOpenClienteContasEmAberto(true);
    }

    const handleCloseClienteContasEmAberto = () => {
        setOpenClienteContasEmAberto(false);
    }

    const handleSearchChange = (searchP) => {
        setSearchParams({ ...searchParams, ...searchP });
    }

    const handleSelect = (selectedRows) => {
        setSelectedRows(selectedRows);
    }

    useEffect(() => {
        setLoading(true);
        setError(false);

        const fetchData = async () => {
            try {
                const response = await ClientesService().getFinanceiro(searchParams);
                const data = response.data;
                console.log(data);
                setClientes(data.data);
                setTotalClientes(data.totalCount);
                setLoading(false);
            } catch (error) {
                setError(true);
                setErrorMessage("Ocorreu um erro ao buscar os clientes.");
                setLoading(false);
            }
        };

        fetchData();
    }, [searchParams]);
    
      const categorizeCliente = (diasAtraso) => {
        if (diasAtraso === 0) return "Em dia";
        if (diasAtraso <= 5) return "Atraso normal";
        if (diasAtraso <= 30) return "Atrasado";
        if (diasAtraso <= 60) return "Beirando inadimplencia";
        return "Inadimplente";
      };
    
      const columns = [
        // Colunas anteriores
        // ... Outras colunas financeiras aqui
        {
            field: 'nome',
            headerName: 'Nome',
            filterable: true,
            sortable: true,
        },
        {
            field: "diasAtraso",
            headerName: "Dias de atraso",
            dataType: 'number',
            filterable: true,
            sortable: true,
        },
        {
            field: "numContasAtraso",
            headerName: "Contas em atraso",
            filterable: false,
            sortable: false,
            valueFormatter: (params) => {
                return <div>
                    {params.contas.filter((conta) => conta.diasAtraso > 0).length}
                    <button
                        onClick={() => handleOpenClienteContasEmAtraso(params.contas.filter((conta) => conta.diasAtraso > 0))}
                    >
                        Ver detalhes
                    </button>
                </div>
            }
        },
        {
            field: "numContasAberto",
            headerName: "Contas em aberto",
            filterable: false,
            sortable: false,
            valueFormatter: (params) => {
                return <div>
                    {params.contas.filter((conta) => conta.situacao === 'aberto' && conta.diasAtraso === 0).length}
                    <button
                        onClick={() => handleOpenClienteContasEmAberto(params.contas.filter((conta) => conta.situacao === 'aberto' && conta.diasAtraso === 0))}
                    >
                        Ver detalhes
                    </button>
                </div>
            }
        },
        {
            field: "lembrete",
            headerName: "Lembrete",
            filterable: true,
            sortable: true,
            dataType: 'filterValues',
            filterValues: ['Sim', 'Não'],
            valueFormatter: (params) => {
                return <div>
                 { params.lembrete ? "Sim" : "Não" }
                 <button
                            onClick={ () => handleOpenEditMensagemLembrete(params) }
                        >
                            Editar
                </button>

                </div>
            },
        },
        {
            field: "mensagemLembrete",
            headerName: "Mensagem de Lembrete",
            filterable: true,
            sortable: true,
            valueFormatter: (params) => {
                return (
                    <div>
                        {params.mensagemLembrete}
                        <button
                            onClick={ () => handleOpenEditMensagemLembrete(params) }
                        >
                            Editar
                        </button>
                    </div>
                )
            }
        },
        {
            field: "situacao",
            headerName: "Situação",
            filterable: true,
            sortable: true,
            dataType: 'filterValues',
            filterValues: ['', 'Em dia', 'Atraso normal', 'Atrasado', 'Beirando inadimplencia', 'Inadimplente'],
            valueFormatter: (params) => categorizeCliente(params.diasAtraso),
        },
    ];

    const fabStyle = {
        position: 'fixed',
        bottom: 16,
        right: 16,
    };

    /* New Cobrança */
    const [cobrancaWindowOpen, setCobrancaWindowOpen] = useState(false);
    const [newCobrancaSuccess, setNewCobrancaSuccess] = useState(false);

    const openNewCobrancaDetails = () => {
        setCobrancaWindowOpen(true);
    }
    const closeNewCobrancaDetails = () => {
        setCobrancaWindowOpen(false);
    }
    const handleAlertClose = (event, reason) => {
        setNewCobrancaSuccess(false);
    }
    const confirmNewCobranca = () => {
        closeNewCobrancaDetails();
        setNewCobrancaSuccess(true);
    }
    /* End new Cobrança */

    return (
        <Container component="main" disableGutters>
            <Header />
            <Container component="main" maxWidth="lg">
                <Box>
                    <Typography align="center" spacing={2} component="h1" variant="h2">
                        Clientes
                    </Typography>

                    <Snackbar open={newCobrancaSuccess} autoHideDuration={4000} onClose={handleAlertClose}>
                        <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
                            Cobranças criadas com sucesso.
                        </Alert>
                    </Snackbar>

                    {cobrancaWindowOpen && (<ClientesCobranca
                        open={cobrancaWindowOpen}
                        onClose={closeNewCobrancaDetails}
                        clientes={selectedRows}
                        onConfirmDisparo={confirmNewCobranca}
                    />)}

                    <Box>
                        {error && <Alert severity="error">{errorMessage}</Alert>}
                        {loading && <LinearProgress />}
                        {!loading && (
                            <Box>
                                <EditMensagemLembrete
                                    open={openEditMensagemLembrete}
                                    onClose={handleCloseEditMensagemLembrete}
                                    lembrete={selectedRow ? selectedRow.lembrete : ""}
                                    mensagemLembrete={selectedRow ? selectedRow.mensagemLembrete : ""}
                                    onSave={handleSaveMensagemLembrete}
                                />

                                <Dialog open={openClienteContasEmAberto} onClose={handleCloseClienteContasEmAberto}>
                                    <DialogTitle>Contas em Aberto</DialogTitle>
                                    <DialogContent>
                                    <TableContainer>
                                        <Table>
                                        <TableHead>
                                            <TableRow>
                                            <TableCell>Nome</TableCell>
                                            <TableCell>Histórico</TableCell>
                                            <TableCell>Data Emissão</TableCell>
                                            <TableCell>Data Vencimento</TableCell>
                                            <TableCell>Valor</TableCell>
                                            <TableCell>Situação</TableCell>
                                            {/* ... outros cabeçalhos ... */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {clienteContasEmAberto.map((conta) => (
                                            <TableRow key={conta.id}>
                                                <TableCell>{conta.nome_cliente}</TableCell>
                                                <TableCell>{conta.historico}</TableCell>
                                                <TableCell>{conta.data_emissao}</TableCell>
                                                <TableCell>{conta.data_vencimento}</TableCell>
                                                <TableCell>{formatCurrency(conta.valor)}</TableCell>
                                                <TableCell>{conta.situacao}</TableCell>
                                                {/* ... outros atributos ... */}
                                            </TableRow>
                                            ))}
                                        </TableBody>
                                        </Table>
                                    </TableContainer>
                                    </DialogContent>
                                    <DialogActions>
                                    <Button onClick={handleCloseClienteContasEmAberto} color="primary">
                                        Fechar
                                    </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog open={openClienteContasEmAtraso} onClose={handleCloseClienteContasEmAtraso}>
                                    <DialogTitle>Contas em Atraso</DialogTitle>
                                    <DialogContent>
                                        <TableContainer>
                                        <Table>
                                            <TableHead>
                                            <TableRow>
                                                <TableCell>Nome</TableCell>
                                                <TableCell>Histórico</TableCell>
                                                <TableCell>Data Emissão</TableCell>
                                                <TableCell>Data Vencimento</TableCell>
                                                <TableCell>Dias de Atraso</TableCell>
                                                <TableCell>Valor Original</TableCell>
                                                <TableCell>Situação</TableCell>
                                                {/* ... outros cabeçalhos ... */}
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {clienteContasEmAtraso.map((conta) => (
                                                <TableRow key={conta.id}>
                                                <TableCell>{conta.nome_cliente}</TableCell>
                                                <TableCell>{conta.historico}</TableCell>
                                                <TableCell>{conta.data_emissao}</TableCell>
                                                <TableCell>{conta.data_vencimento}</TableCell>
                                                <TableCell>{conta.diasAtraso}</TableCell>
                                                <TableCell>{formatCurrency(conta.valor)}</TableCell>
                                                <TableCell>{conta.situacao}</TableCell>
                                                {/* ... outros atributos ... */}
                                                </TableRow>
                                            ))}
                                            </TableBody>
                                        </Table>
                                        </TableContainer>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseClienteContasEmAtraso} color="primary">
                                        Fechar
                                        </Button>
                                    </DialogActions>
                                </Dialog>


                                <Datatable
                                    data={clientes}
                                    dataTotalCount={totalClientes}
                                    columns={columns}
                                    sort
                                    onSort={handleSearchChange}
                                    pagination
                                    paginationPerPage={10}
                                    paginationRowsPerPageOptions={[10, 20, 30, 50]}
                                    paginationComponentOptions={{
                                        rowsPerPageText: 'Clientes por página:',
                                        rangeSeparatorText: 'de',
                                        noRowsPerPage: false,
                                        selectAllRowsItem: false,
                                    }}
                                    onPagination={handleSearchChange}
                                    filter
                                    filterPlaceholder="Pesquisar"
                                    onFilter={handleSearchChange}
                                    searchParams={searchParams}
                                    selectableRows
                                    onSelect={handleSelect}
                                />

                                {selectedRows.length > 0 && 
                                    <Fab variant="extended" sx={fabStyle} onClick={openNewCobrancaDetails}>
                                        Criar cobrança ({ selectedRows.length })
                                    </Fab>
                                }

                            </Box>
                        )}
                    </Box>
                </Box>
            </Container>
        </Container>
    )

}

export default Clientes;