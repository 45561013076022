//Verifica se está logado, se estiver, envia para a página de dashboard, se não, envia para a página de login
// Path: react-app\src\pages\Home.js
import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';

const Home = () => {
    const { user } = useAuth();

    if (user && user.user.role === 1) {
        return (<Navigate to='/dashboard' />);
    }

    if (user && user.user.role === 0) {
        return (<Navigate to='/vendedor/dashboard' />);
    }

    return (<Navigate to='/login' />);
};

export default Home;