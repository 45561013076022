import React, { useState, useEffect } from "react";
import { TextField, Alert, Box, Typography, LinearProgress, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Datatable from "../Datatable";
import VendasService from "../../services/VendasService";
import dayjs from "dayjs";
import formatCurrency from "../../utils/formatCurrency";

const ClienteDetails = ({ open, onClose, cliente }) => {

    const [compras, setCompras] = useState([]); // Vendas do cliente
    const [totalCompras, setTotalCompras] = useState(0); // Total de vendas do cliente
    const [loading, setLoading] = useState(true); // Loading
    const [error, setError] = useState(false); // Error
    const [errorMessage, setErrorMessage] = useState(''); // Error message

    const [searchParams, setSearchParams] = useState({}); // Parâmetros de busca

    //Itens mais comprados
    const [itensMaisComprados, setItensMaisComprados] = useState([]); // Itens mais comprados pelo cliente
    const [totalItensMaisComprados, setTotalItensMaisComprados] = useState(0); // Total de itens mais comprados pelo cliente
    const [loadingItensMaisComprados, setLoadingItensMaisComprados] = useState(true); // Loading
    const [errorItensMaisComprados, setErrorItensMaisComprados] = useState(false); // Error
    const [errorMessageItensMaisComprados, setErrorMessageItensMaisComprados] = useState(''); // Error message
    const [itensMaisCompradosSearchParams, setItensMaisCompradosSearchParams] = useState({}); // Parâmetros de busca
    const onItensMaisCompradosChangeParams = (searchParams) => {
        setItensMaisCompradosSearchParams(searchParams);    
    }
    const handleItensMaisCompradosSearch = () => {
        setLoadingItensMaisComprados(true);
        setErrorItensMaisComprados(false);

        VendasService().getItensMaisComprados({cliente: cliente.id, ...itensMaisCompradosSearchParams})
        .then((response) => {
            setItensMaisComprados(response.data.itens);
            setTotalItensMaisComprados(response.data.totalItens);
            setLoadingItensMaisComprados(false);
        })
        .catch((error) => {
            setErrorItensMaisComprados(true);
            setErrorMessageItensMaisComprados(error.response.data.message);
            setLoadingItensMaisComprados(false);
        });
    }
    useEffect(() => {
        handleItensMaisCompradosSearch(itensMaisCompradosSearchParams);
    }, [itensMaisCompradosSearchParams]);



    const handleSearch = () => {

        setLoading(true);
        setError(false);

        VendasService().get({cliente: cliente.id, ...searchParams})
        .then((response) => {
            setCompras(response.data.vendas);
            setTotalCompras(response.data.totalVendas); 
            setLoading(false);
        })
        .catch((error) => {
            setError(true);
            setErrorMessage(error.response.data.message);
            setLoading(false);
        });
    }

    useEffect(() => {
        handleSearch(searchParams);
    }, [searchParams]);

    const onChangeSearch = (searchParams) => {
        setSearchParams(searchParams);
    }

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
      if (open) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    }, [open]);

    //Controle dos itens da venda selecionada
    const [itensDetailsOpen, setClienteDetailsOpen] = useState(false); // Gerencia o modal de detalhes dos itens de venda do cliente
    const [itens, setItens] = useState([]); // Itens de venda do cliente

    console.log(cliente);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll='body'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">Detalhes do cliente</DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    <Box p={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="body1" component="p" gutterBottom>
                            <TextField label="Nome" value={cliente.nome} variant="outlined" fullWidth disabled />
                        </Typography>
                    </Box>

                    <Box p={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body1" component="p" gutterBottom sx={ { marginRight:"8px"} }>
                            <TextField label="Email" value={cliente.email} variant="outlined" disabled />
                        </Typography>
                        <Typography variant="body1" component="p" gutterBottom sx={ { marginRight:"8px"} }>
                            <TextField label="Telefone" value={cliente.fone} variant="outlined" fullWidth disabled />
                        </Typography>
                        <Typography variant="body1" component="p" gutterBottom sx={ { marginRight:"8px"} }>
                            <TextField label="CPF/CNPJ" value={cliente.cpf_cnpj} variant="outlined" fullWidth disabled />
                        </Typography>
                    </Box>

                    <Box p={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body1" component="p" gutterBottom sx={ { marginRight:"8px"} }>
                            <TextField label="CEP" value={cliente.cep} variant="outlined" fullWidth disabled />
                        </Typography>
                        <Typography variant="body1" component="p" gutterBottom sx={ { marginRight:"8px"} }>
                            <TextField label="Endereço" value={cliente.endereco} variant="outlined" fullWidth disabled />
                        </Typography>
                    </Box>
                    <Box p={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body1" component="p" gutterBottom sx={ { marginRight:"8px"} }>
                            <TextField label="Cidade" value={cliente.cidade} variant="outlined" fullWidth disabled />
                        </Typography>
                        <Typography variant="body1" component="p" gutterBottom sx={ { marginRight:"8px"} }>
                            <TextField label="Estado" value={cliente.uf} variant="outlined" fullWidth disabled />
                        </Typography>
                    </Box>

                    {loadingItensMaisComprados && (<LinearProgress />)}
                    {errorItensMaisComprados && (<Alert severity="error">{errorMessageItensMaisComprados}</Alert>)}
                    {itensMaisComprados.length > 0 && (
                        <Box>
                            <Typography variant="h6" component="h2" gutterBottom>
                                Itens mais comprados
                            </Typography>
                            <Datatable
                                columns={[
                                    { field: 'sku', headerName: 'SKU', sortable: false, valueFormatter: (item) => item.sku || '-' },
                                    { field: 'nome', headerName: 'Nome', sortable: true },
                                    { field: 'quantidade', headerName: 'Quantidade', sortable: true },
                                    { field: 'valorTotal', headerName: 'Valor total', sortable: true, valueFormatter: (item) => formatCurrency(item.valorTotal) },
                                ]}
                                data={itensMaisComprados}
                                dataTotalCount={totalItensMaisComprados}
                                pagination
                                paginationPerPage={5}
                                paginationRowsPerPageOptions={[5, 10, 15]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Itens por página',
                                    rangeSeparatorText: 'de',
                                    noRowsPerPage: false,
                                    selectAllRowsItem: false,
                                    selectAllRowsItemText: 'Todos'
                                }}
                                onPagination={onItensMaisCompradosChangeParams}
                            />
                        </Box>
                    )}

                    {loading && <LinearProgress />}
                    {error && <Alert severity="error">{errorMessage}</Alert>}
                    {compras.length > 0 && (
                        <Box>
                            <Typography variant="h6" component="h2" gutterBottom>
                                Compras
                            </Typography>
                            <Datatable
                                columns={[
                                    { field: 'id', headerName: 'ID', sortable: false },
                                    { field: 'data', headerName: 'Data', sortable: true, valueFormatter: (compra) => dayjs(compra.data).format('DD/MM/YYYY') },
                                    { field: 'valorTotal', headerName: 'Valor total', sortable: true, valueFormatter: (compra) => formatCurrency(compra.valorTotal) },
                                    { field: 'status', headerName: 'Status', sortable: true },
                                ]}
                                data={compras}
                                dataTotalCount={totalCompras}
                                pagination
                                paginationPerPage={5}
                                paginationRowsPerPageOptions={[5, 10, 15]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Vendas por página:',
                                    rangeSeparatorText: 'de',
                                    noRowsPerPage: false,
                                    selectAllRowsItem: false,
                                    selectAllRowsItemText: 'Todos',
                                }}
                                sort
                                onPagination={onChangeSearch}
                                onSort={onChangeSearch}
                                onRowClick={(compra) => {
                                    setItens(compra.itens);
                                    setClienteDetailsOpen(true);
                                }}
                            />
                        </Box>
                    )}

                </DialogContentText>
            </DialogContent>

            <Dialog
                open={itensDetailsOpen}
                onClose={() => setClienteDetailsOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Itens da venda</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Datatable
                            columns={[
                                { field: 'nome', headerName: 'Nome' }, 
                                { field: 'valor', headerName: 'Valor', valueFormatter: (item) => formatCurrency(item.valor) }, 
                                { field: 'quantidade', headerName: 'Quantidade' },
                                { field: 'valorTotal', headerName: 'Valor total', valueFormatter: (item) => formatCurrency(item.valorTotal) },
                            ]}
                            data={itens}
                            dataTotalCount={itens.length}
                        />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Dialog>
    )

}

export default ClienteDetails;