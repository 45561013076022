import HttpService from "./HttpService";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const adapter = new AdapterDayjs();

const NovosClientesService = () => {

    const get = (searchParams) => {
        return HttpService().get(`resource/vendedor/novos_clientes`, { params: searchParams });
    };

    const post = (contatos) => {
        return HttpService().post(`resource/vendedor/novos_clientes`, { contatos });
    };

    return {
        get,
        post,
    };

};

export default NovosClientesService;