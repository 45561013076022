import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import formatCurrency from '../utils/formatCurrency';

const MetaBar = ({
    label,
    data,
    formatAsCurrency,
}) => {

    const progressValue = data.value/data.total*100;

    return (
        <Box sx={{ width: '100%' }}>
            {label.length > 0 && (
                <Typography variant="h6" component="div" gutterBottom>
                    {label}
                </Typography>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress sx={{height: '30px'}} variant="determinate" value={progressValue} />
                </Box>
                <Box>
                    <Typography variant="body2" color="text.secondary">
                        {formatAsCurrency ? formatCurrency(data.value) : data.value}/{formatAsCurrency ? formatCurrency(data.total) : data.total}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );

};

MetaBar.propTypes = {
    label: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    formatAsCurrency: PropTypes.bool,
};

MetaBar.defaultProps = {
    label: "",
    data: {
        total: 0,
        value: 0,
    },
    formatAsCurrency: false,
};

export default MetaBar;