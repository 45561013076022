import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';

function LineChart({ title, data, labels, id }) {
    useEffect(() => {
        const ctx = document.getElementById(id);
        if (!ctx) {
            return;
        }

        const myChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                    label: title,
                    data: data,
                    backgroundColor: '#1976d2',
                    borderColor: '#1565c0',
                    borderWidth: 2,
                    pointBackgroundColor: '#fff',
                    pointBorderColor: '#1976d2',
                    pointRadius: 5,
                    pointHoverRadius: 7
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        grid: {
                            color: '#ccc'
                        }
                    },
                    x: {
                        grid: {
                            color: '#ccc'
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    }
                },
                layout: {
                    padding: {
                        top: 5,
                        right: 5,
                        bottom: 5,
                        left: 5
                    },
                    background: 'white' // Define o fundo do gráfico como branco
                }
            }
        });

        if(ctx && ctx.style) {
            ctx.style.backgroundColor = 'white';
        }

        return () => {
            myChart.destroy();
        };
    }, [title, data, labels, id]);

    return (
        <canvas id={id} />
    );
}

export default LineChart;
