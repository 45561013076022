import HttpService from "./HttpService";

const DisparosService = () => {

    const get = (searchParams) => {
        return HttpService().get("resource/vendedor/disparos", { params: searchParams });
    };

    const getFinanceiro = (searchParams) => {
        return HttpService().get("resource/financeiro/disparos", { params: searchParams } );
    };

    return {
        get,
        getFinanceiro,
    };
};

export default DisparosService;