import React from 'react';
import { Paper, Typography } from '@mui/material';

function Indicador({ titulo, descricao, valor, detalhes }) {
    return (
        <Paper sx={{ backgroundColor: '#1976d2', padding: '15px', borderRadius: '10px', color: 'white', display: 'flex', margin: '0 auto' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <Typography variant="subtitle1">{titulo}</Typography>
                    <Typography variant="body2" sx={{ opacity: 0.8 }}>{descricao}</Typography>
                </div>
                <div>
                    <Typography variant="h6">{valor}</Typography>
                    <Typography variant="body2">{detalhes}</Typography>
                </div>
            </div>
        </Paper>
    );
}

export default Indicador;
