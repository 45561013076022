import { useEffect, useState } from "react";
import Datatable from "../Datatable";
import { Alert, LinearProgress, Box } from "@mui/material";
import DisparosService from "../../services/DisparosService";
import dayjs from "dayjs";

const CampanhasList = () => {

    const [disparos, setDisparos] = useState([]);
    const [totalDisparos, setTotalDisparos] = useState(0);
    const [searchParams, setSearchParams] = useState({tipo: 'todos'});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSearchChange = (searchP) => {
        setSearchParams({ ...searchParams, ...searchP });
    }

    useEffect(() => {
        setLoading(true);
        setError(false);

        const fetchData = async () => {
            try {
                const response = await DisparosService().get(searchParams);
                const data = response.data;
                setDisparos(data.data);
                setTotalDisparos(data.totalCount);
                setLoading(false);
            } catch (error) {
                setError(true);
                setErrorMessage("Ocorreu um erro ao buscar os disparos.");
                setLoading(false);
            }
        };

        fetchData();
    }, [searchParams]);

    const getClientesNames = (clientes) => {
        let str = '';
        for(let i=0;i<clientes.length;i++){
            str += clientes[i].nome + ' | '
        }
        str += '';
        return str;
    }

    const columns = [
        {
            field: 'clientes',
            headerName: 'Clientes',
            valueFormatter: (params) => {
                return params.clientes ? getClientesNames(params.clientes) : '';
            }
        },
        {
            field: 'mensagem',
            headerName: 'Mensagem',
            filterable: true,
            sortable: true,
        },
        {
            field: 'status',
            headerName: 'Status',
            filterable: true,
            sortable: true,
        },
        {
            field: 'created',
            headerName: 'Data Criação',
            filterable: true,
            sortable: true,
            dataType: 'date',
            valueFormatter: (params) => {
                return params.created ? dayjs(params.created).format("DD/MM/YYYY") : '';
            },
        },
        {
            field: 'processed',
            headerName: 'Data de Processamento',
            filterable: true,
            sortable: true,
            dataType: 'date',
            valueFormatter: (params) => {
                return params.processed ? dayjs(params.processed).format("DD/MM/YYYY") : '';
            },
        }
    ];

    return (
        <Box>
            {error && <Alert severity="error">{errorMessage}</Alert>}
            {loading && <LinearProgress />}
            {!loading && (
            <Box>
                <Datatable
                    data={disparos}
                    dataTotalCount={totalDisparos}
                    columns={columns}
                    sort
                    onSort={handleSearchChange}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20, 30, 50]}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Disparos por página:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                    }}
                    onPagination={handleSearchChange}
                    filter
                    filterPlaceholder="Pesquisar"
                    onFilter={handleSearchChange}
                    searchParams={searchParams}
                />
            </Box>
            )}
        </Box>
    )

}

export default CampanhasList;