import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";

const EditMensagemLembrete = ({ open, onClose, lembrete, mensagemLembrete, onSave }) => {
  const [novaMensagemLembrete, setNovaMensagemLembrete] = useState(mensagemLembrete);
  const [novoLembrete, setNovoLembrete] = useState(lembrete);

  const handleSave = () => {
    onSave(novaMensagemLembrete, novoLembrete);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Editar Mensagem de Lembrete</DialogTitle>
      <DialogContent>
        <FormControl>
            <InputLabel id="lembrete-label">Ativar envio de lembrete:</InputLabel>
            <Select
                m={5}
                labelId="lembrete-label"
                id="lembrete-select"
                value={novoLembrete ? "Sim" : "Não"}
                onChange={(e) => setNovoLembrete(e.target.value === "Sim")}
            >
                <MenuItem value="Sim">Sim</MenuItem>
                <MenuItem value="Não">Não</MenuItem>
            </Select>
        </FormControl>
        <TextField
          label="Mensagem de Lembrete"
          fullWidth
          multiline
          value={novaMensagemLembrete}
          onChange={(e) => setNovaMensagemLembrete(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSave} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMensagemLembrete;
