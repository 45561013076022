import { useAuth } from "../context/AuthContext";
import AuthService from "../services/AuthService";

const CheckCookie = ({children}) => {

    const { user, loginByToken } = useAuth();

    if(!user){
        if(localStorage.getItem('token')){
            AuthService.loginByToken(localStorage.getItem('token')).then((data) => {
                loginByToken({ token: data.token, user: data.user });
            }).catch((err) => {
                console.log(err);
            });

        }
    }

    return children;
}

export default CheckCookie;