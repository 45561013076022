import HttpService from "./HttpService";

const CobrancaService = {
  get: (searchParams) => {
    return HttpService().get(`resource/financeiro/cobrancas`, { params: searchParams });
  },

  post: (data) => {
    return HttpService().post(`resource/financeiro/cobrancas`, data);
  },

  launchCobranca: (cobranca) => {
    return HttpService().post('/resource/financeiro/cobranca/launch', cobranca);
  },

  cancelCobranca: (cobranca) => {
    return HttpService().post('/resource/financeiro/cobranca/cancel', cobranca); 
  },

  editCobranca: (cobranca, mensagem) => {
    return HttpService().post('/resource/financeiro/cobranca', { cobranca, mensagem } );
  }
};

export default CobrancaService;
