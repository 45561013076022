import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";

import VendedorDashboard from "./pages/Vendedor/Dashboard";
import VendedorMetas from "./pages/Vendedor/Metas";
import VendedorNovosClientes from "./pages/Vendedor/NovosClientes";
import VendedorClientes from "./pages/Vendedor/Clientes";
import VendedorCampanhas from "./pages/Vendedor/Campanhas";

import VendedorWhats from "./pages/Vendedor/Whats";

import User from "./pages/User";
import Metas from "./pages/Metas";
import Vendedores from "./pages/Vendedores";
import Vendas from "./pages/Vendas";

import Clientes from "./pages/Clientes";
import Cobrancas from "./pages/Cobrancas";
import Disparos from "./pages/Disparos";

import Base from "./components/Vendedor/Base";

import CheckCookie from "./middlewares/CheckCookie";
import ProtectedRouteAdmin from "./middlewares/ProtectedRouteAdmin";
import ProtectedRoute from "./middlewares/ProtectedRoute";

import { AuthProvider } from './context/AuthContext';

import { CookiesProvider } from 'react-cookie';

function App() {

  return (
    <Router>
      <CookiesProvider>
        <AuthProvider>
          <Routes>
              <Route element={<CheckCookie><Home/></CheckCookie>} path="/" exact />
              <Route element={<CheckCookie><Login/></CheckCookie>} path="/login" exact />
              <Route element={<CheckCookie><Register/></CheckCookie>} path="/register" exact/>


              <Route element={<CheckCookie><ProtectedRouteAdmin><Dashboard/></ProtectedRouteAdmin></CheckCookie>} path="/dashboard" exact />
              <Route element={<CheckCookie><ProtectedRouteAdmin><Metas/></ProtectedRouteAdmin></CheckCookie>} path="/metas" exact /> 
              <Route element={<CheckCookie><ProtectedRouteAdmin><Vendedores/></ProtectedRouteAdmin></CheckCookie>} path="/vendedores" exact /> 
              <Route element={<CheckCookie><ProtectedRouteAdmin><Vendas/></ProtectedRouteAdmin></CheckCookie>} path="/vendas" exact />

              <Route element={<CheckCookie><ProtectedRouteAdmin><Clientes/></ProtectedRouteAdmin></CheckCookie>} path="/clientes" exact />
              <Route element={<CheckCookie><ProtectedRouteAdmin><Cobrancas/></ProtectedRouteAdmin></CheckCookie>} path="/cobrancas" exact />
              <Route element={<CheckCookie><ProtectedRouteAdmin><Disparos/></ProtectedRouteAdmin></CheckCookie>} path="/disparos" exact />

              <Route element={<CheckCookie><ProtectedRouteAdmin><Base><VendedorWhats/></Base></ProtectedRouteAdmin></CheckCookie>} path="/configwhats" exact /> 


              <Route element={<CheckCookie><ProtectedRoute><Base><VendedorDashboard/></Base></ProtectedRoute></CheckCookie>} path="/vendedor/dashboard" exact />
              <Route element={<CheckCookie><ProtectedRoute><Base><VendedorMetas /></Base></ProtectedRoute></CheckCookie>} path="/vendedor/metas" exact />
              <Route element={<CheckCookie><ProtectedRoute><Base><VendedorNovosClientes /></Base></ProtectedRoute></CheckCookie>} path="/vendedor/novos-clientes" exact />
              <Route element={<CheckCookie><ProtectedRoute><Base><VendedorClientes /></Base></ProtectedRoute></CheckCookie>} path="/vendedor/clientes" exact />

              <Route element={<CheckCookie><ProtectedRoute><Base><VendedorCampanhas /></Base></ProtectedRoute></CheckCookie>} path="/vendedor/campanhas" exact />

              <Route element={<CheckCookie><ProtectedRoute><Base><VendedorWhats/></Base></ProtectedRoute></CheckCookie>} path="/vendedor/whats" exact /> 

              <Route element={<CheckCookie><ProtectedRouteAdmin><User/></ProtectedRouteAdmin></CheckCookie>} path="/user" exact />
              <Route element={<Logout/>}  path="/logout" exact />
              <Route element={<CheckCookie><Home/></CheckCookie>} path="*" />
          </Routes>
        </AuthProvider>
      </CookiesProvider>
  </Router>
  );
}

export default App;
