import Header from "../components/Header";
import Footer from "../components/Footer";
import { Box, Container, Typography, Tooltip, Grid } from "@mui/material";
import MetasMapa  from "../components/Metas/MetasMapa";

const Metas = () => {

    return (
        <Container component="main" disableGutters>
            <Header/>
                <Container component="main" maxWidth="lg" >
                    <Box>
                        <Typography align="center" spacing={2} component='h1' variant="h2">
                            Metas
                        </Typography>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item>
                                <MetasMapa />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            <Footer/>
        </Container>
    );

};

export default Metas;