import { Box, Container, Grid, Link, Typography } from "@mui/material";

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer">
            <Container maxWidth="lg">
                <Box mt={3} sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Typography variant="body2" color="textSecondary" align="center">
                    &#174; {currentYear} Pro B2B | By Solutions4Y
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center">
                    Feito com <Typography color="textSecondary" component="span">&#10084;</Typography> e <Typography color="textSecondary" component="span">&#9749;</Typography>
                </Typography>
                </Box>
            </Container>
        </footer>
    );
};

export default Footer;