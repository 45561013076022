import { Box, Typography, Grid } from "@mui/material";
import MetasMapa  from "../../components/Vendedor/MetasMapa";

const VendedorMetas = () => {

    return (
        <Box>
            <Typography align="center" spacing={2} component='h1' variant="h2">
                Metas
            </Typography>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >
                <Grid item>
                    <MetasMapa />
                </Grid>
            </Grid>
        </Box>
    )

}

export default VendedorMetas;