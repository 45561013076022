import { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const loginByToken = ({ token, user }) => {
    setUser({ token: token, user: user });
  };

  const login = (token, user) => {
    // Aqui você pode realizar a autenticação do usuário e, se for bem-sucedido, setar o usuário com setUser
    setUser({ token, user });
    localStorage.setItem('token', token);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('token');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loginByToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);