import React, { useState } from 'react';
import { Modal, TextField, Button, Divider, IconButton, Typography, Paper } from '@mui/material';
import { ArrowDownward as AddIcon, ArrowUpward as RemoveIcon, Settings } from '@mui/icons-material';
import styles from './styles.module.css';
import dayjs from 'dayjs';

function Widget(props) {
  const classes = styles;
  const [isMinimized, setIsMinimized] = useState(false);

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  
  const [options, setOptions] = useState(props.defaultOptions || {});

  const handleOptionsChange = (option) => {
    setOptions({
      ...options,
      [option.field]: option.value,
    });
  };

  const handleConfirmOptions = () => {
    props.onChangeOptions(options);
    handleCloseModal();
  };


  return (
    <Paper className={classes.root}>
      <Modal open={openModal} onClose={handleCloseModal}>
        <div className={classes.modal}>
          <Typography variant="h6" sx={{mb: 2}}>Opções</Typography>
          {props.options.map((option) => (
            <TextField
              key={option.field}
              id={option.field}
              label={option.label}
              variant="outlined"
              size="small"
              onChange={(e) => handleOptionsChange({ field: option.field, value: e.target.value })}
              value={options[option.field] || ""}
              type={option.tipoDado === "data" ? "date" : "text"}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }} 
              sx= {{ mb: 2 }}
            />
          ))}
          <Button variant="contained" onClick={handleConfirmOptions}>Confirmar</Button>
        </div>
      </Modal>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.title}>
          {props.title}
          <Typography align="center" spacing={2} component='p' variant="body2" >
            {props.options.map((option) => (
              <Typography key={option.field} variant="body2" component="span" sx={{ mr: 2 }}>
                {option.label}: {option.tipoDado === 'data' ? dayjs(options[option.field]).format("DD/MM/YYYY") : options[option.field]}
              </Typography>
            ))}
          </Typography>
        </Typography>
          <div className={classes.actions}>
          {props.options.length > 0 && (
            <IconButton aria-label="Opções" onClick={handleOpenModal}>
              <Settings />
            </IconButton>
          )}
          <IconButton aria-label={isMinimized ? 'Maximizar' : 'Minimizar'} onClick={handleMinimize}>
            {isMinimized ? <AddIcon /> : <RemoveIcon />}
          </IconButton>
        </div>
      </div>
      <Divider />
      {!isMinimized && <div className={classes.content}>{props.children}</div>}
    </Paper>
  );
}

Widget.defaultProps = {
  title: "",
  options: [],
  onChangeOptions: () => {},
};

export default Widget;