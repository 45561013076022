import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  LinearProgress,
  Alert,
} from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Datatable from "../components/Datatable";
import DisparosService from "../services/DisparosService";
import dayjs from "dayjs";

const Disparos = () => {
  const [disparos, setDisparos] = useState([]);
  const [totalDisparos, setTotalDisparos] = useState(0);
  const [searchParams, setSearchParams] = useState({});
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSearchChange = (searchP) => {
    setSearchParams({ ...searchParams, ...searchP });
  }

  const columns = [
    {
      field: 'cliente',
      headerName: 'Cliente',
      valueFormatter: (params) => {
        return params.cliente.nome ? params.cliente.nome : '';
      },
    },
    {
      field: 'numero',
      headerName: 'Número',
      filterable: true,
      sortable: true,
      valueFormatter: (params) => {
        return params.numero;
      }
    },
    {
      field: 'mensagem',
      headerName: 'Mensagem',
      filterable: true,
      sortable: true,
    },
    {
      field: 'dataProcessamento',
      headerName: 'Data de envio',
      filterable: true,
      sortable: true,
      dataType: 'date',
      valueFormatter: (params) => {
        return params.dataProcessamento ? dayjs(params.dataProcessamento).format("DD/MM/YYYY") : '';
      },
    },
    {
      field: 'tipoDisparo',
      headerName: 'Tipo de disparo',
      filterable: true,
      sortable: true,
    },
    {
      field: 'status',
      headerName: 'status',
      filterable: true,
      sortable: true,
    }
  ];
  

  useEffect(() => {

    setLoading(true);
    setError(false);

    const fetchDisparos = async () => {
      try {
        const response = await DisparosService().getFinanceiro(searchParams);
        const data = response.data;
        setDisparos(data.data);
        setTotalDisparos(data.totalCount);
        setLoading(false);
      } catch (error) {
        setError(true);
        setErrorMessage("Ocorreu um erro ao buscar os disparos.");
        setLoading(false);
      }
    };

    fetchDisparos();
  }, [searchParams]);

  return (
    <Container component="main" disableGutters>
      <Header />
      <Container component="main" maxWidth="lg">
        <Box>
          <Typography align="center" spacing={2} component="h1" variant="h2">
            Disparos
          </Typography>
          {error && <Alert severity="error">{errorMessage}</Alert>}
          {loading ? (
            <LinearProgress />
          ) : (
            <Datatable
              data={disparos}
              dataTotalCount={totalDisparos}
              columns={columns}
              sort
              onSort={handleSearchChange}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 30, 50]}
              paginationComponentOptions={{
                  rowsPerPageText: 'Disparos por página:',
                  rangeSeparatorText: 'de',
                  noRowsPerPage: false,
                  selectAllRowsItem: false,
              }}
              onPagination={handleSearchChange}
              filter
              filterPlaceholder="Pesquisar"
              onFilter={handleSearchChange}
              searchParams={searchParams}
            />
          )}
        </Box>
      </Container>
      <Footer />
    </Container>
  );
};

export default Disparos;
