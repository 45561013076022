import HttpService from "./HttpService";

const FinanceiroDashboardService = () => {

  const getIndicadores = (startDate, endDate) => {
    // return HttpService().get(`resource/financeiro/indicadores?startDate=${startDate}&endDate=${endDate}`);

    return {
      taxaInadimplencia: {
        quantidade: 120,
        valor: 15000,
      },
      taxaRecuperacao: {
        quantidade: 90,
        valor: 10000,
      },
      tempoMedioPagamento: 45,
      inadimplencia: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80, 95], // Dados para o gráfico de linha
      datas: [
        '01/01/2022', '02/01/2022', '03/01/2022', '04/01/2022', '05/01/2022', '06/01/2022', '07/01/2022', '08/01/2022', '09/01/2022', '10/01/2022',
        '11/01/2022', '12/01/2022', '13/01/2022', '14/01/2022', '15/01/2022', '16/01/2022', '17/01/2022', '18/01/2022', '19/01/2022', '20/01/2022',
        '21/01/2022', '22/01/2022', '23/01/2022', '24/01/2022', '25/01/2022', '26/01/2022', '27/01/2022', '28/01/2022', '29/01/2022', '30/01/2022', '31/01/2022'
      ], // Labels para o gráfico de linha
      distribuicaoIdade: [30, 20, 50], // Dados para o gráfico de pizza
      faixasIdade: ['18-30', '31-50', '51+'], // Labels para o gráfico de pizza
    }; 
  };

  return {
    getIndicadores,
  };
};

export default FinanceiroDashboardService;
