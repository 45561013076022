import { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Box, Container, Typography, Grid, Fab, Modal, TextField, Button } from "@mui/material";
import { DeleteForever as DeleteIcon, Add as AddIcon, Edit as EditIcon } from "@mui/icons-material";
import Datatable from "../components/Datatable";

const Vendedores = () => {

    const [data, setData] = useState([]);
    const [dataTotalCount, setDataTotalCount] = useState(0);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            sortable: true,
            filterable: true,
        },
        {
            field: 'nome',
            headerName: 'Nome',
            sortable: true,
            filterable: true,
        },
        {
            field: 'email',
            headerName: 'E-mail',
            sortable: true,
            filterable: true,
        }
    ];

    useEffect(() => {
        updateData();
    }, []);

    const updateData = (searchOptions) => {
        setData([
            {
                "id": 1,
                "nome": "João",
                "email": "idsjrf@djif.com",
            },
            {
                "id": 2,
                "nome": "Maria",
                "email": "wiejf2@gmail.com"
            },
            {
                "id": 3,
                "nome": "Pedro",
                "email": "sdfsf@fdsfse4fs"
            },
        ]);
        setDataTotalCount(3);
    };

    const [createNewVendedor, setCreateNewVendedor] = useState(false);
    const [newVendedor, setNewVendedor] = useState({ nome: '', email: '' });//id, nome, email

    const toggleCreateNewVendedor = () => {
        setCreateNewVendedor(!createNewVendedor);
    };

    const handleNewVendedorChange = (e, name) => {
        setNewVendedor({...newVendedor, [name]: e.target.value});
    }

    const submitNewVendedor = () => {
        //Enviar dados para o backend
        //...
        const newData = newVendedor;
        newData.id = data.length + 1;
        setData([...data, newData]);
        setNewVendedor({});
        toggleCreateNewVendedor();
    };

    const [selectedVendedores, setSelectedVendedores] = useState([]);//[row1, row2, row3
    const onSelectVendedor = (selectedRows) => {
        setSelectedVendedores(selectedRows);
    };

    const deleteVendedores = () => {
        //Enviar dados para o backend
        //...
        //Janela para ter certeza que quer deletar, pois a ação é irreversível

        const areYouSure = window.confirm('Tem certeza que deseja deletar os vendedores selecionados? Essa ação é irreversível!');

        if(!areYouSure) return;
        const newData = data.filter((row) => !selectedVendedores.includes(row));
        setSelectedVendedores([]);
        setData(newData);   
    };

    const [editVendedor, setEditVendedor] = useState({});//{id, nome, email}
    const [isOpenEditVendedor, setIsOpenEditVendedor] = useState(false);
    const openEditVendedor = (row) => {
        setEditVendedor(row);
        setIsOpenEditVendedor(true);
    };
    const closeEditVendedor = () => {
        setEditVendedor({});
        setIsOpenEditVendedor(false);
    };
    const handleEditVendedorChange = (e, name) => {
        setEditVendedor({...editVendedor, [name]: e.target.value});
    };
    const submitEditVendedor = () => {
        //Enviar dados para o backend
        //...
        const newData = data.map((row) => {
            if(row.id === editVendedor.id) return editVendedor;
            return row;
        });
        setData(newData);
        closeEditVendedor();
    };

    return (
        <Container component="main" disableGutters>
            <Header/>
                <Container component="main" maxWidth="lg" >
                    <Fab onClick={toggleCreateNewVendedor} color="primary" aria-label="add" sx={{position: 'fixed', bottom: 16, right: 16}}>
                        <AddIcon />
                    </Fab>
                    <Modal
                        open={createNewVendedor}
                        onClose={toggleCreateNewVendedor}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4, }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Novo Vendedor
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Formulário de cadastro de novo vendedor
                            </Typography>

                            <TextField id="nome" onChange={(e) => {handleNewVendedorChange(e, 'nome')}} label="Nome" variant="outlined" margin="normal" fullWidth autoFocus required/>
                            <TextField id="email" onChange={(e) => {handleNewVendedorChange(e, 'email')}} label="E-mail" variant="outlined" margin="normal" fullWidth required/>

                            <Button onClick={submitNewVendedor} variant="contained" sx={{ mt: 2 }} fullWidth>
                                Cadastrar
                            </Button>

                        </Box>
                    </Modal>

                    <Modal
                        open={isOpenEditVendedor}
                        onClose={closeEditVendedor}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4, }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Editar Vendedor
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Formulário de edição de vendedor
                            </Typography>

                            <TextField id="nome" onChange={(e) => {handleEditVendedorChange(e, 'nome')}} label="Nome" variant="outlined" margin="normal" fullWidth autoFocus required value={editVendedor.nome}/>
                            <TextField id="email" onChange={(e) => {handleEditVendedorChange(e, 'email')}} label="E-mail" variant="outlined" margin="normal" fullWidth required value={editVendedor.email}/>
                            <Button onClick={submitEditVendedor} variant="contained" sx={{ mt: 2 }} fullWidth>
                                Salvar
                            </Button>

                        </Box>
                    </Modal>

                    {selectedVendedores.length > 0 && (
                        <Fab onClick={deleteVendedores} color="error" aria-label="delete" sx={{position: 'fixed', bottom: 16, right: 80}}>
                            <DeleteIcon />
                        </Fab>
                    )}

                    <Box>
                        <Typography align="center" spacing={2} component='h1' variant="h2">
                            Vendedores
                        </Typography>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item>
                                <Datatable
                                    columns={columns}
                                    data={data}
                                    dataTotalCount={dataTotalCount}
                                    pagination
                                    paginationPerPage={10}
                                    paginationRowsPerPageOptions={[10, 20, 30]}
                                    paginationComponentOptions={{
                                        rowsPerPageText: 'Vendedores por página:',
                                        rangeSeparatorText: 'de',
                                    }}
                                    onPagination={(searchOptions) => {updateData(searchOptions)}}
                                    filter
                                    filterPlaceholder="Pesquisar"
                                    onFilter={(searchOptions) => {updateData(searchOptions)}}
                                    sort
                                    onSort={(searchOptions) => {updateData(searchOptions)}}
                                    selectableRows
                                    onSelect={(selectedRows) => {onSelectVendedor(selectedRows)}}
                                    actions={[
                                        {
                                            name: 'Editar',
                                            icon: <EditIcon />,
                                            onClick: openEditVendedor,
                                        }
                                    ]}

                                />


                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            <Footer/>
        </Container>
    );
};

export default Vendedores;