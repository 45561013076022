import React, {useEffect, useRef, useState} from "react";
import { Box, Typography } from '@mui/material';

import api, {socket} from "../../services/WhatsAPI";
import { useAuth } from '../../context/AuthContext';

const VendedorWhats = () => {
    
    const [token, setToken] = useState("");
    const [session, setSession] = useState("");
    const [qrCode, setQrCode] = useState("");
    const animationRef = useRef(null);
    const { user } = useAuth();

    const [successMessage, setSuccessMessage] = useState("");
    
    useEffect(() => {
        async function startSession(session, token) {
            try {
                const config = {
                    headers: {Authorization: `Bearer ${token}`}
                };
    
                const checkConn = await api.get(`${session}/check-connection-session`, config);
                if (!checkConn.data.status) {
                    await signSession();
                } else {
                    //Ta tudo certo e funcionando
                    setSuccessMessage("Tudo ok.");
                }
            } catch (e) {
                //setSuccessMessage("Houve um erro. Recarregue a página.");
                console.log(e);
            }
        }
    
        async function signSession() {
            const config = {
                headers: {Authorization: `Bearer ${token}`}
            };
    
            await api.post(`${session}/start-session`, null, config)
        }

        socket.on("qrCode", (qrCode) => {
            console.log(qrCode);
            if (session === qrCode.session) {
                setQrCode(qrCode.data);
                if (animationRef.current !== null) {
                    animationRef.current.classList.remove("animation");
                }
            }
        });
        socket.off("session-logged").on("session-logged", (status) => {
            console.log(status);
            if (status.session === session) {
                if (token) {
                    //Ta tudo certo e funcionando
                    setSuccessMessage("Tudo ok.");
                }
            }
        });

        if(user && user.user){
            setSession(user.user.whatsSession)
            setToken(user.user.whatsToken)
            startSession(user.user.whatsSession, user.user.whatsToken);
        }
    }, [user, session, token]);

    return (
        <Box>
            <Typography align="center" spacing={2} component='h1' variant="h2">
                Whats
            </Typography>

            {
                qrCode !== "" && successMessage !== "Tudo ok." && (
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <img
                            ref={animationRef}
                            className={"animation noselect"}
                            src={qrCode}
                            alt={"Smartphone"}
                            draggable={"false"}
                        />
                        <Typography align="center" spacing={2} component='span' variant="h3">
                            Scan QRCode
                        </Typography>
                    </div>
                )
            }

            {
                qrCode === "" && successMessage !== "Tudo ok." && (
                    <div>
                        <small>
                            Carregando informações...
                        </small>
                    </div>
                )
            }

            {  
                successMessage === "" ? null : (
                    <div>
                        {successMessage}
                    </div>
                )    
            }
            
        </Box>
    )

}

export default VendedorWhats;