import Footer from "../Footer";
import Header from "../Header";
import Container from '@mui/material/Container';

const Base = ({ children }) => {
    return (
        <Container component="main" disableGutters >
            <Header />
            <Container component="main" maxWidth="lg" >
                {children}
            </Container>
            <Footer />
        </Container>
    );
}

export default Base;