import HttpService from "./HttpService";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const adapter = new AdapterDayjs();

const VendasService = () => {

    const get = ({ cliente }) => {

        // Vamos gerar um retorno aleatório para testar o carregamento
        const vendas = [];

        for(let i = 0; i < 10; i++){
            const venda = {
                id: i + 1,
                status: Math.random() < 0.5 ? 'Concluída' : 'Em andamento',
                data: adapter.date(Math.random() * (new Date().getTime())),
                valorTotal: Math.floor(Math.random() * 1000),
                itens: [],
            };
            for(let j = 0; j < 5; j++){
                venda.itens.push({
                    nome: 'Item ' + (j + 1),
                    valor: Math.floor(Math.random() * 100),
                    quantidade: Math.floor(Math.random() * 10) + 1,
                    valorTotal: Math.floor(Math.random() * 1000),
                });
            }
            vendas.push(venda);
        }

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({data: {vendas, totalVendas: vendas.length}});
            }, 1000);
        });

        // return HttpService().get(`clientes/${clienteId}/vendas`);
    };

    const getItensMaisComprados = (searchParams) => {

        // Vamos gerar um retorno aleatório para testar o carregamento
        const itens = [];

        for(let i = 0; i < 10; i++){
            itens.push({
                sku: (i + 1) * 123,
                nome: 'Item ' + (i + 1),
                quantidade: Math.floor(Math.random() * 100),
                valorTotal: Math.floor(Math.random() * 1000),
            });
        }

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({data: {itens, totalItens: itens.length}});
            }, 1000);
        });
        
        // return HttpService().get(`clientes/${clienteId}/mais-vendidos`);
    };


    return {
        get,
        getItensMaisComprados,
    };

};

export default VendasService;