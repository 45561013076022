import Widget from "../Widget";
import { Typography } from "@mui/material";

const WidgetVendas = () => {
    const handleChangeOptions = (options) => { 
        console.log(options);
    };

    const vendasOptions = [
        {
            field: 'dataInicio',
            label: 'Data Início',
            tipoDado: 'data',
        },
        {
            field: 'dataFim',
            label: 'Data Fim',
            tipoDado: 'data',
        },
    ];

    return (
        <Widget title="Vendas" onChangeOptions={handleChangeOptions} options={vendasOptions}>
            <Typography>
                Vendas
            </Typography>
        </Widget>
    )
};

export default WidgetVendas;