import React, { useEffect, useState } from 'react';
import Widget from '../../Widget';
import Indicador from '../../Indicador';
import { LinearProgress, Typography } from '@mui/material';
import IndicadoresService from "../../../services/FinanceiroDashboard";
import LineChart from '../../Graficos/LineChart'; // Componente que combina gráfico de linha e barras
import PieChart from '../../Graficos/PieChart';
import dayjs from 'dayjs';

const IndicadoresKPI = () => {
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
    const [endDate, setEndDate] = useState(dayjs());
    const [dataLoading, setDataLoading] = useState(true);
    const [indicadores, setIndicadores] = useState({});
    const [error, setError] = useState(null);

    const fetchIndicadores = async () => {
        console.log(startDate, endDate);
        setDataLoading(true);
        try{
            const data = await IndicadoresService().getIndicadores(startDate, endDate);
            setIndicadores(data);
        } catch (e) {
            setError('Erro ao processar os indicadores. Verifique as datas ou entre em contato com o suporte.');
        } finally {
            setDataLoading(false);
        }
    }

    useEffect(() => {
        fetchIndicadores();
        console.log("Testando o carregamento");
    }, []);

    return (
        <Widget
            title="Indicadores-chave de desempenho (KPIs)"
            options={[
                { field: 'startDate', label: 'Data de Início', tipoDado: 'data', value: startDate },
                { field: 'endDate', label: 'Data de Fim', tipoDado: 'data', value: endDate }
            ]}
            onChangeOptions={({ startDate, endDate }) => {
                setStartDate(startDate);
                setEndDate(endDate);
                fetchIndicadores();
            }}
            defaultOptions = {{
                startDate: startDate,
                endDate: endDate,
            }}
        >
            <div>
                <p>Escolha um intervalo de datas para visualizar os indicadores.</p>
                {dataLoading ? (
                    <LinearProgress />
                ) : error ? (
                    <Typography variant="body1" color="error">
                        {error}
                    </Typography>
                ) : (
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(450px, 1fr))', gap: '20px' }}>
                        <Indicador titulo="Taxa de inadimplência" descricao="A porcentagem de contas em atraso em relação ao total de contas" valor={indicadores ? ((indicadores.taxaInadimplencia.quantidade / 100) * 100).toFixed(2) + "%" : '...'} detalhes={`(Em número: ${indicadores ? indicadores.taxaInadimplencia.quantidade : '...'}, em valor R$: ${indicadores ? indicadores.taxaInadimplencia.valor : '...'})`} />
                        <Indicador titulo="Taxa de recuperação" descricao="A porcentagem de dívidas cobradas com sucesso em relação ao total de dívidas" valor={indicadores ? ((indicadores.taxaRecuperacao.quantidade / 100) * 100).toFixed(2) + "%" : '...'} detalhes={`(Em número: ${indicadores ? indicadores.taxaRecuperacao.quantidade : '...'}, em valor R$: ${indicadores ? indicadores.taxaRecuperacao.valor : '...'})`} />
                        <Indicador titulo="Tempo médio de pagamento" descricao="O tempo médio que leva para os clientes pagarem suas dívidas após uma cobrança ou negociação" valor={indicadores ? indicadores.tempoMedioPagamento + " dias" : '...'} />
                        <Indicador titulo="Inadimplência ao longo do tempo" descricao="Tendência histórica da inadimplência ao longo do tempo separado em períodos" valor={<LineChart title="Inadimplência" data={indicadores?.inadimplencia} labels={indicadores?.datas} id="inadimplenciaChart" />} />
                        <Indicador titulo="Distribuição de dívidas por faixa de idade" descricao="Gráfico de pizza mostrando a distribuição de dívidas por faixa de idade" valor={<PieChart title="Dívidas por faixa de idade" data={indicadores?.distribuicaoIdade} labels={indicadores?.faixasIdade} id="distribuicaoIdadeChart" />} />
                    </div>
                )}
            </div>
        </Widget>
    );
};

export default IndicadoresKPI;
