import { Box, Typography, Modal, Paper, Button, LinearProgress, Tooltip } from "@mui/material";
import { useState, useEffect } from "react";
import SeedDashboard from "../../utils/SeedDashboardVendedor";
import MetaBar from "../../components/MetaBar";
import Brasil from "../../components/Brasil";
import formatCurrency from "../../utils/formatCurrency";
import dayjs from "dayjs";
import Datatable from "../../components/Datatable";
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Widget from "../../components/Widget";


const VendedorDashboard = () => {

    const [data, setData] = useState({});
    const [dataLoading, setDataLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const json = await SeedDashboard(); //Read /modelos.txt only for develop on real api should implement dataPeriodToLoad.from and dataPeriodToload.To
            console.log(json);
            setData(json);
            setDataLoading(false);
        };
        fetchData();
    }, []);

    const [isSearchMetaOpen, setIsSearchMetaOpen] = useState(false);
    const toggleSearchMeta = () => {
        setIsSearchMetaOpen(!isSearchMetaOpen);
    };

    const [selectedMeta, setSelectedMeta] = useState(null);
    const handleMetaSelect = (meta) => {
        setSelectedMeta(meta);
        toggleSearchMeta();
    };

    const metasColumns = [
        {
            field: 'id',
            headerName: 'ID',
            filterable: false,
            sortable: false,
        },
        {
            field: 'nome',
            headerName: 'Nome',
            filterable: true,
            sortable: true,
        },
        {
            field: 'descricao',
            headerName: 'Descrição',
            filterable: true,
            sortable: true,
        },
        {
            field: 'meta',
            headerName: 'Meta',
            filterable: false,
            sortable: true,
            valueFormatter: (row) => formatCurrency(row.meta),
        },
        {
            field: 'data_inicio',
            headerName: 'Data de início',
            filterable: true,
            sortable: true,
            valueFormatter: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
        },
        {
            field: 'data_fim',
            headerName: 'Data de fim',
            filterable: true,
            sortable: true,
            valueFormatter: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
        },
    ];

    //Dashboard do vendedor, irá conter os seguintes componentes:
    // - Mercado conquistado { Irá visualizar no mapa do Brasil o mercado conquistado* e o mercado total*, podendo filtrar por estado }
    // - Prospecção de novos clientes { Irá visualizar os clientes que foram enviados ao CRM e quantos geraram vendas, podendo visualizar as vendas geradas por cada cliente }
    // - Metas { Irá selecionar a meta e visualizar o progresso das vendas no mapa do brasil podendo filtrar por estado }
    // * No caso do mercado conquistado, representa o total de clientes do vendedor registrados no sistema com compra ou não
    // * No caso do mercado total, representa o total do mercado menos os clientes do vendedor e de todos os outros vendedores

    return (
        <Box>
            <Typography variant="h4" component="h1" gutterBottom textAlign='center' mt={2}>
                Dashboard
            </Typography>

            {dataLoading && <LinearProgress />}
            {!dataLoading && (<Box>
                <Widget title={
                    <Typography variant="h6" component="span" gutterBottom textAlign='center' mt={2}>
                        Mercado atingido
                        <Tooltip title="Total de empresas do setor e clientes atingidos (Que foram enviados ao CRM ou já são clientes)" placement="top">
                            <HelpOutlineIcon style={{fontSize: '1rem'}} />
                        </Tooltip>
                    </Typography>} 
                >
                    <MetaBar data={{total: data.MercadoAnalytics.total, value: data.MercadoAnalytics.total_aquisicoes}}/>
                    <Brasil data={data.MercadoAnalytics.estados} total={data.MercadoAnalytics.total} value={data.MercadoAnalytics.total_aquisicoes} sumField={'total_aquisicoes'} totalField={'total'} />
                </Widget>

                <Widget 
                    title={
                        <Typography variant="h6" component="span" gutterBottom textAlign='center' mt={2}>
                            Prospecção de novos clientes
                            <Tooltip title="Clientes que foram enviados ao CRM e que geraram vendas (1ª venda gerada no período)" placement="top">
                                <HelpOutlineIcon style={{fontSize: '1rem'}} />
                            </Tooltip>
                        </Typography>
                    }

                    options = {[
                        {
                            field: 'dataInicio',
                            label: 'Data de início',
                            tipoDado: 'data',
                        },
                        {
                            field: 'dataFim',
                            label: 'Data de fim',
                            tipoDado: 'data',
                        }
                    ]}

                    defaultOptions = {{
                        dataInicio: dayjs().subtract(1, 'month'),
                        dataFim: dayjs(),
                    }}

                    onChangeOptions={(options) => console.log(options)}
                >
                    <Brasil noTotal data={data.NovosClientesAnalytics.estados} value={data.NovosClientesAnalytics.novasAquisicoes} sumField={'novasAquisicoes'} />
                </Widget>

                <Widget
                    title={
                        <Typography variant="h6" component="span" gutterBottom textAlign='center' mt={2}>
                            Metas
                            <Tooltip title="Metas de vendas e valor conquistado de vendas no período da meta" placement="top">
                                <HelpOutlineIcon style={{fontSize: '1rem'}} />
                            </Tooltip>
                        </Typography>
                    }
                >
                    <Box display='flex' justifyContent='center' spacing={2} >
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<SearchIcon />}
                            onClick={() => toggleSearchMeta()}
                        >
                            Selecionar meta
                        </Button>
                    </Box>

                    {data.Metas && (<Modal
                        open={isSearchMetaOpen}
                        onClose={toggleSearchMeta}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >   
                        <Box sx={{
                            p: 4,
                        }}>
                            <Paper elevation={3} sx={{
                                p: 4,
                            }}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Selecione a meta
                                </Typography>
                                
                                <Datatable
                                    data={data.Metas}
                                    columns={metasColumns} 
                                    onRowClick={(meta) => handleMetaSelect(meta)}
                                />
                            </Paper>
                        </Box>
                    </Modal>)}

                    {selectedMeta && (
                        <Box>
                            <MetaBar
                                formatAsCurrency
                                label={selectedMeta.nome}
                                data={{
                                    total: selectedMeta.meta,
                                    value: selectedMeta.vendas_realizadas
                                }}
                            />
                            <Brasil
                                formatAsCurrency
                                data={selectedMeta.estados}
                                total={selectedMeta.meta}
                                value={selectedMeta.vendas_realizadas}
                                sumField="vendas_realizadas"
                                totalField="meta"
                            />
                        </Box>
                    )}
                </Widget>
            </Box>)}
        </Box>
    );
}

export default VendedorDashboard;