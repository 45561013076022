import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  FormControl,
  Alert,
} from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Datatable from "../components/Datatable";
import CobrancaService from "../services/CobrancaService"; // Certifique-se de importar o serviço correto
import dayjs from "dayjs";
import formatCurrency from "../utils/formatCurrency";

const Cobrancas = () => {
  const [cobrancas, setCobrancas] = useState([]);
  const [totalCobbrancas, setTotalCobrancas] = useState(0);
  const [searchParams, setSearchParams] = useState({});
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedCobranca, setSelectedCobranca] = useState(null);
  const [clienteModalOpen, setClienteModalOpen] = useState(false);

  const handleOpenClienteContasEmAtraso = (row) => {
    if(!['Novo', 'Cancelado'].includes(row.status)) {
      return true;
    }
    setSelectedCobranca(row);
    setNovaMensagem(row.mensagem);
    setClienteModalOpen(true);
  };

  const handleCloseClienteModal = () => {
    setSelectedCobranca(null);
    setClienteModalOpen(false);
  };

  const handleSearchChange = (searchP) => {
    setSearchParams({ ...searchParams, ...searchP });
  }

  const cancelarCobrancas = (cobranca) => {
    if (!['Novo', 'Aguardando processamento', 'Processando'].includes(cobranca.status)) {
      return true;
    }

    setLoading(true);
    setError(false);

    const cancelCobranca = async (cobranca) => {
      try {
        const response = await CobrancaService.cancelCobranca(cobranca);
        const data = response.data;

        if (data.success) {
          setCobrancas((prevCobrancas) =>
            prevCobrancas.map((cob) =>
              cob._id === cobranca._id ? {...cobranca, status: "Cancelado"} : cob
            )
          );
        } else {
          setError(true);
          setErrorMessage(data.message);
        }
        setLoading(false);
      } catch (error) {
        setError(true);
        setErrorMessage("Ocorreu um erro ao tentar mudar o estado da cobrança.");
        setLoading(false);
      }
    };

    cancelCobranca(cobranca);
  }

  const launchCobrancas = (cobranca) => {

    if (!['Novo', 'Cancelado'].includes(cobranca.status)) {
      return true;
    }

    setLoading(true);
    setError(false);

    const changeCobranca = async (cobranca) => {
      try {
        const response = await CobrancaService.launchCobranca(cobranca);
        const data = response.data;

        if (data.success) {
          setCobrancas((prevCobrancas) =>
            prevCobrancas.map((cob) =>
              cob._id === cobranca._id ? {...cobranca, status: "Aguardando processamento"} : cob
            )
          );
        } else {
          setError(true);
          setErrorMessage(data.message);
        }
        setLoading(false);
      } catch (error) {
        setError(true);
        setErrorMessage("Ocorreu um erro ao tentar mudar o estado da cobrança.");
        setLoading(false);
      }
    };

    changeCobranca(cobranca);
  }

  const columns = [
    {
      field: 'clientes',
      headerName: 'Clientes',
      valueFormatter: (params) => {
        return params.clientes ? params.clientes.length : '';
      },
    },
    {
      field: 'mensagem',
      headerName: 'Mensagem',
      filterable: true,
      sortable: true,
    },
    {
      field: 'usuario',
      headerName: 'Usuário',
      valueFormatter: (params) => {
        return params.usuario ? params.usuario.name : '';
      },
      filterable: true,
      sortable: true,
    },
    {
      field: 'dataCriacao',
      headerName: 'Data de Criação',
      filterable: true,
      sortable: true,
      dataType: 'date',
      valueFormatter: (params) => {
        return params.dataCriacao ? dayjs(params.dataCriacao).format("DD/MM/YYYY") : '';
      },
    },
    {
      field: 'dataProcessamento',
      headerName: 'Data de Processamento',
      filterable: true,
      sortable: true,
      dataType: 'date',
      valueFormatter: (params) => {
        return params.dataProcessamento ? dayjs(params.dataProcessamento).format("DD/MM/YYYY") : '';
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      filterable: true,
      sortable: true,
    },
    {
      field: 'tipoDisparo',
      headerName: 'Tipo de Disparo',
      filterable: true,
      sortable: true,
    },
    {
      field: 'dataAgendamento',
      headerName: 'Data de Agendamento',
      filterable: true,
      sortable: true,
      dataType: 'date',
      valueFormatter: (params) => {
        return params.dataAgendamento ? dayjs(params.dataAgendamento).format("DD/MM/YYYY") : '';
      },
    },
    {
      field: 'action',
      headerName: 'Ações',
      filterable: false,
      sortable: false,
      valueFormatter: (params) => {
        return (
          <div>
            <Button  color="primary" variant="outlined"
              onClick={() => { handleOpenClienteContasEmAtraso(params) }}
            >
              Editar  
            </Button> 
            <Button color="success" variant="outlined"
              onClick={() => { launchCobrancas(params) }}
            >
              Lançar
            </Button> 
            <Button color="error" variant="outlined"
              onClick={() => { cancelarCobrancas(params) }}
            >
              Cancelar
            </Button>
          </div>
        );
      }
    }
  ];
  

  useEffect(() => {

    setLoading(true);
    setError(false);

    const fetchCobrancas = async () => {
      try {
        const response = await CobrancaService.get(searchParams); // Substitua pela chamada correta para obter as cobranças
        const data = response.data;
        setCobrancas(data.data);
        setTotalCobrancas(data.totalCount);
        setLoading(false);
      } catch (error) {
        setError(true);
        setErrorMessage("Ocorreu um erro ao buscar as cobranças.");
        setLoading(false);
      }
    };

    fetchCobrancas();
  }, [searchParams]);

  const [novaMensagem, setNovaMensagem] = useState('');

  const updateCobrancaMessage = (cob, msg) => {

    if (!["Novo", "Cancelado"].includes(cob.status)) {
      return true;
    }

    setLoading(true);
    setError(false);

    const changeCobranca = async (cobranca, mensagem) => {
      try {
        const response = await CobrancaService.editCobranca(cobranca, mensagem);
        const data = response.data;

        if (data.success) {
          setCobrancas((prevCobrancas) =>
            prevCobrancas.map((cob) =>
              cob._id === cobranca._id ? {...cobranca, mensagem: mensagem} : cob
            )
          );
        } else {
          setError(true);
          setErrorMessage(data.message);
        }

        handleCloseClienteModal();
        setLoading(false);
      } catch (error) {
        setError(true);
        setErrorMessage("Ocorreu um erro ao tentar mudar o estado da cobrança.");
        setLoading(false);
      }
    };

    changeCobranca(cob, msg);

  }

  const workOnMessage = (message, cliente) => {

    cliente.contas = cliente.contas.filter((el) => { return el.situacao === 'aberto' && el.diasAtraso > 0});

    const replaceVariables = {
      '{nome}': cliente.nome,
      '{fantasia}': cliente.fantasia,
      '{numero_contas_atraso}': cliente.contas.length.toString(),
      // ... Adicione mais variáveis conforme necessário
    };
  
    Object.entries(replaceVariables).forEach(([variable, value]) => {
      message = message.replace(new RegExp(variable, 'g'), value);
    });

    const contaVariablePattern = /{conta_([^}]+)}/g;
    const contaMatches = message.match(contaVariablePattern);

    if (contaMatches) {
      let modifiedMessage = message;

      const startIndex = message.indexOf(contaMatches[0]);
      const endIndex = message.lastIndexOf(contaMatches[contaMatches.length - 1]);

      const prefix = message.substring(0, startIndex);
      const suffix = message.substring(endIndex + contaMatches[contaMatches.length - 1].length);

      const values = cliente.contas.map((conta) => {
        let contaMessage = modifiedMessage.substring(startIndex, endIndex + contaMatches[contaMatches.length - 1].length);

        contaMessage = contaMessage.replace(contaVariablePattern, (match, property) => {
          if (conta[property] !== undefined) {
            if (property === 'valor') {
              return formatCurrency(conta[property]);
            } 
            else if (property === 'dias_atraso') {
              return conta['diasAtraso'];
            }
            else {
              return conta[property]
            }
          }
          return match;
        });

        return contaMessage;
      });

      return prefix + values.join('\n') + suffix;
    }

    return message;
  };

  return (
    <Container component="main" disableGutters>
      <Header />
      <Container component="main" maxWidth="lg">
        <Box>
          <Typography align="center" spacing={2} component="h1" variant="h2">
            Cobranças
          </Typography>
          {/* Renderizar o restante do conteúdo da página */}
          {/* ... */}
          {error && <Alert severity="error">{errorMessage}</Alert>}
          {loading ? (
            <LinearProgress />
          ) : (
            <Datatable
              data={cobrancas}
              columns={columns}
              dataTotalCount={totalCobbrancas}
              sort
              onSort={handleSearchChange}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 30, 50]}
              paginationComponentOptions={{
                  rowsPerPageText: 'Cobranças por página:',
                  rangeSeparatorText: 'de',
                  noRowsPerPage: false,
                  selectAllRowsItem: false,
              }}
              onPagination={handleSearchChange}
              filter
              filterPlaceholder="Pesquisar"
              onFilter={handleSearchChange}
              searchParams={searchParams}
              // Outras props do Datatable
            />
          )}
          <Dialog open={clienteModalOpen} onClose={handleCloseClienteModal}>
            
            <DialogTitle>
              <FormControl style={{ width: "100%" }}>
                <TextField
                  label="Mensagem"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={novaMensagem}
                  onChange={(event) => setNovaMensagem(event.target.value)}
                />
              </FormControl>
              Clientes da Cobrança
            </DialogTitle>
            <DialogContent>
              {selectedCobranca && selectedCobranca.clientes.map((cliente) => (
                <div key={cliente.id}>
                  <Typography>Cliente: {cliente.nome}</Typography>
                  <Typography>Whatsapp: {cliente.fone}</Typography>
                  {/* Renderizar outras informações do cliente, se necessário */}
                  <Typography>Contas em Atraso:</Typography>
                  <ul>
                    {cliente.contas.filter((el) => { return el.situacao === 'aberto' && el.diasAtraso > 0}).map((conta) => (
                      <li key={conta.id}>
                        <Typography>Histórico - {conta.historico}</Typography>
                        <Typography>Data vencimento - {conta.data_vencimento}</Typography>
                        <Typography>Dias atraso - {conta.diasAtraso}</Typography>
                        <Typography>Valor original - {formatCurrency(conta.valor)}</Typography>
                        {/* Renderizar outras informações da conta */}
                      </li>
                    ))}
                  </ul>
                  <Typography>Prévia da mensagem: </Typography>
                  <TextField style={{ width: '100%' }}multiline disabled value={workOnMessage(novaMensagem, cliente)} />
                  <br></br>
                  <br></br>
                </div>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseClienteModal} color="primary">
                Fechar
              </Button>
              { selectedCobranca && selectedCobranca.mensagem !== novaMensagem && (
                <Button color="primary" onClick={() => updateCobrancaMessage(selectedCobranca, novaMensagem)}>
                  Atualizar mensagem
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
      <Footer />
    </Container>
  );
};

export default Cobrancas;
