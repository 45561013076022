import { useEffect, useState } from "react";
import Datatable from "../Datatable";
import { Alert, LinearProgress, Box, Tooltip, Tabs, Tab, Fab, Snackbar } from "@mui/material";
import NovosClientesService from "../../services/NovosClientesService ";
import dayjs from "dayjs";
import formatCurrency from "../../utils/formatCurrency";

const NovosClientesList = () => {

    const [clientes, setClientes] = useState([]);
    const [totalClientes, setTotalClientes] = useState(0);
    const [searchParams, setSearchParams] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [selectedRows, setSelectedRows] = useState([]);

    const handleSearchChange = (searchP) => {
        setSearchParams({ ...searchParams, ...searchP });
    }

    const handleSelect = (selectedRows) => {
        setSelectedRows(selectedRows);
    }

    const [successMessage, setSuccessMessage] = useState('');

    const fetchData = async () => {
        try {
            const response = await NovosClientesService().get(searchParams);
            const data = response.data;
            setClientes(data.data);
            setTotalClientes(data.totalCount);
            setLoading(false);
            setSelectedRows([]);
        } catch (error) {
            setError(true);
            setErrorMessage("Ocorreu um erro ao buscar os leads.");
            setLoading(false);
        }
    };

    const sendToCRM = () => {
        const sendContatosToCRM = async (contatos) => {
            try{
                const response = await NovosClientesService().post(contatos);
                setSuccessMessage('Leads enviados com sucesso para o CRM');
                setError(false);
                setLoading(false);
                setSelectedRows([]);
                fetchData();
            } catch (error) {
                setError(true);
                setErrorMessage('Não foi possível enviar os leads.');
                setLoading(false);
            }
        }

        setLoading(true);
        setError(false);
        sendContatosToCRM(selectedRows);
    }

    useEffect(() => {
        // Define um temporizador para limpar a mensagem de sucesso após 5 segundos
        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
            }, 5000);

            // Limpa o temporizador quando o componente é desmontado ou quando a mensagem de sucesso muda
            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    useEffect(() => {
        setLoading(true);
        setError(false);
        fetchData();
    }, [searchParams]);

    const columns = [
        {
            field: 'name',
            headerName: 'Nome',
            filterable: true,
            sortable: true,
        },
        {
            field: 'phone',
            headerName: 'Crafted Phone',
            filterable: true,
            sortable: true,
        },
        {
            field: 'website',
            headerName: 'Crafted Website',
            filterable: true,
            sortable: true,
        },
        {
            field: 'category',
            headerName: 'Category',
            filterable: true,
            sortable: true,
        },
        {
            field: 'uf',
            headerName: 'Estado',
            filterable: true,
            sortable: true,
        },
        {
            field: 'nome_municipio',
            headerName: 'Nome Municipio',
            filterable: false,
            sortable: false,
        },
        {
            field: 'codigo_municipio',
            headerName: 'Código Municipio',
            filterable: true,
            sortable: true,
        },
        {
            field: 'address',
            headerName: 'Address',
            filterable: true,
            sortable: true,
        },
        {
            field: 'number',
            headerName: 'Number',
            filterable: true,
            sortable: true,
        }

    ];

    const fabStyle = {
        position: 'fixed',
        bottom: 16,
        right: 16,
    };


    return (
        <Box>
            {error && <Alert severity="error">{errorMessage}</Alert>}
            {successMessage && <Alert severity="success">{successMessage}</Alert>}
            {loading && <LinearProgress />}
            {!loading && (
            <Box>
                <Datatable
                    data={clientes}
                    dataTotalCount={totalClientes}
                    columns={columns}
                    sort
                    onSort={handleSearchChange}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20]}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Leads por página:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                    }}
                    onPagination={handleSearchChange}
                    filter
                    filterPlaceholder="Pesquisar"
                    onFilter={handleSearchChange}
                    searchParams={searchParams}
                    selectableRows
                    onSelect={handleSelect}
                />
            </Box>
            )}
        {selectedRows.length > 0 && !loading && 
            <Fab variant="extended" sx={fabStyle} onClick={sendToCRM}>
                Enviar para o CRM ({ selectedRows.length })
            </Fab>
        }
        </Box>
    )

}

export default NovosClientesList;