import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER
});

const AuthService = {
    login: (email, password) => {
        return api.post('/login', { email, password })
            .then(response => response.data)
            .catch(error => {
                throw new Error(error.response.data.error);
            });
    },
    loginByToken: (token) => {
        return api.post('/loginByToken', { token })
            .then(response => response.data)
            .catch(error => {
                throw new Error(error.response.data.error);
            });
    },
};

export default AuthService;