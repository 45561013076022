import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Box,
  Typography,
  LinearProgress,
  Alert
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CobrancaService from "../../services/CobrancaService";

const ClientesCobranca = ({ open, onClose, clientes, onConfirmDisparo }) => {
  const [mensagem, setMensagem] = useState(""); // Mensagem to be composed

  const [loading, setLoading] = useState(false); // Loading flag for API call
  const [error, setError] = useState(false); // Error flag for API call
  const [errorMessage, setErrorMessage] = useState(""); // Error message for API call

  const handleClose = () => {
    setMensagem("");
    setLoading(false);
    setError(false);
    setErrorMessage("");
    onClose();
  };

  const handleConfirmDisparo = () => {
    setLoading(true);
    setError(false);
    setErrorMessage("");

    // Compose the data for the API call
    let data = {
      clientes: clientes,
      mensagem,
    };

    sendDisparoData(data);

  };
  
  const sendDisparoData = (data) => {
      CobrancaService.post(data)
      .then((response) => {
        setLoading(false);
        onConfirmDisparo(); // Notify the parent component about the successful disparo
        handleClose(); // Close the dialog
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        setErrorMessage(
          "Houve um erro ao tentar criar a cobrança. Por favor tente novamente ou entre em contato com o suporte caso o erro persista."
        );
      });
  }

  const variaveisDisp = ['nome', 'fantasia', 'numero_contas_atraso', 'conta_data_emissao', 'conta_data_vencimento', 'conta_historico', 'conta_situacao', 'conta_valor', 'conta_dias_atraso'];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="cliente-disparo-dialog-title"
    >
      <DialogTitle id="cliente-disparo-dialog-title">
        Criar nova cobrança ({clientes.length})
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <TextField
            label="Mensagem"
            multiline
            rows={4}
            fullWidth
            value={mensagem}
            onChange={(e) => setMensagem(e.target.value)}
            placeholder="Digite sua mensagem aqui..."
            margin="dense"
          />

          <Box mt={2}>
            <Typography variant="subtitle1">Legenda das variáveis:</Typography>
            {/* Display a legend of variables that can be used in the message */}
            <Typography variant="body2">
                {variaveisDisp.map((variavel) => (
                    "{"+variavel+"} " 
                ))}
              <br></br>
              Exemplo: Olá {"{nome}"}! Isto irá inserir o nome do cliente na mensagem.
            </Typography>
          </Box>

          {loading && <LinearProgress />}
          {error && <Alert severity="error">{errorMessage}</Alert>}
        </DialogContentText>
      </DialogContent>

      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleConfirmDisparo} variant="contained" color="primary" disabled={loading}>
          Confirmar
        </Button>
      </Box>
    </Dialog>
  );
};

export default ClientesCobranca;
