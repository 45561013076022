//A header de usuário não logado só retornar os links de login e cadastro
// Path: react-app\src\components\Header\HeaderNotLogged.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button, Stack } from '@mui/material';

const HeaderNotLogged = () => {

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Pro B2B
                </Typography>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" color="info" href="/login">Login</Button>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};

export default HeaderNotLogged;