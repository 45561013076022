import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../context/AuthContext';
import { 
        TextField, 
        Button, 
        Link, 
        Grid, 
        Box, 
        Typography, 
        Container,
        Snackbar,
        Alert
     } from "@mui/material";
import Footer from "../components/Footer";
import Header from "../components/Header";

import AuthService from '../services/AuthService';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login, user } = useAuth();

    const navigate = useNavigate();

    //Notification
    const [openNotification, setOpenNotification] = useState(false);
    const [notification, setNoficiation] = useState('');
    const [notificationSeverity, setNotificationSeverity] = useState('error');

    //Fix para ir ao dashboard, quando acessamos a página de login pela primeira vez e precisamos autenticar via cookies
    useEffect(() => {
        if (user) {
            navigate('/dashboard');
        }
    }, [user, navigate]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }    
        setOpenNotification(false);
    };

    useEffect(() => {
        if (notification) {
            setOpenNotification(true);

            setTimeout(() => {
                setOpenNotification(false);
            }, 3000);
        }
    }, [notification]);

    const handleSubmit = (event) => {
        event.preventDefault();

        AuthService.login(email, password).then((response) => {
            login(response.token, response.user);
            //Redirect to dashboard
            if(response.user.role === 1)
                navigate('/dashboard');
            else
                navigate('/dashboard_vendedor');
        }).catch((error) => {
            console.log(error);
            //Show notification with error message
            setNoficiation(error.error);
            setOpenNotification(true);
            setNotificationSeverity('error');
        });
    };

    return (
        <Container component="main" disableGutters >
            <Header/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    m: 3,
                }}
            >
                <Snackbar open={openNotification} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={notificationSeverity} sx={{ width: '100%' }}>
                        {notification}
                    </Alert>
                </Snackbar>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Senha"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                        
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Entrar
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Esqueceu a senha?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/register" variant="body2">
                                    {"Não tem uma conta? Cadastre-se"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
            <Footer/>
        </Container>
    );
};

export default Login;