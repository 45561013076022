import { useEffect, useState } from "react";
import Datatable from "../Datatable";
import { Alert, LinearProgress, Box, Tooltip, Tabs, Tab, Fab, Snackbar } from "@mui/material";
import ClienteDetails from "./ClienteDetails";
import ClientesService from "../../services/ClientesService";
import ClienteDisparo from "./ClienteDisparo";
import dayjs from "dayjs";
import formatCurrency from "../../utils/formatCurrency";
import { PanoramaFishEyeSharp } from "@mui/icons-material";

const ClientesList = () => {

    const [clientes, setClientes] = useState([]);
    const [totalClientes, setTotalClientes] = useState(0);
    const [searchParams, setSearchParams] = useState({tipo: 'todos'});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [selectedRows, setSelectedRows] = useState([]);

    const [openClienteDetails, setOpenClienteDetails] = useState(false); // Gerencia o modal de detalhes do cliente
    const [clienteDetails, setClienteDetails] = useState({}); // Dados do cliente que serão exibidos no modal de detalhes

    const handleOpenClienteDetails = (cliente) => {
        //setClienteDetails(cliente);
        //setOpenClienteDetails(true);
    }

    const handleCloseClienteDetails = () => {
        setOpenClienteDetails(false);
    }

    const handleSearchChange = (searchP) => {
        setSearchParams({ ...searchParams, ...searchP });
    }

    const handleSelect = (selectedRows) => {
        setSelectedRows(selectedRows);
    }

    useEffect(() => {
        setLoading(true);
        setError(false);

        const fetchData = async () => {
            try {
                const response = await ClientesService().get(searchParams);
                const data = response.data;
                setClientes(data.data);
                setTotalClientes(data.totalCount);
                setLoading(false);
            } catch (error) {
                setError(true);
                setErrorMessage("Ocorreu um erro ao buscar os clientes.");
                setLoading(false);
            }
        };

        fetchData();
    }, [searchParams]);

    const handleChangeClienteType = (event) => {
        setSearchParams({ ...searchParams, tipo: event});
    }

    const columns = [
        {
            field: 'nome',
            headerName: 'Nome',
            filterable: true,
            sortable: true,
        },
        {
            field: 'fone',
            headerName: 'Telefone',
            filterable: true,
            sortable: true,
        },
        {
            field: 'dataUltimaCompra',
            headerName: 'Última compra',
            filterable: true,
            sortable: true,
            dataType: 'date',
            valueFormatter: (params) => {
                return params.dataUltimaCompra ? dayjs(params.dataUltimaCompra).format("DD/MM/YYYY") : '';
            },
        },
        {
            field: 'numeroPedidos',
            headerName: 'Número de pedidos realizados',
            filterable: true,
            sortable: true,
        },
        {
            field: 'periodicidade',
            headerName: 'Periodicidade de compra (dias)',
            filterable: true,
            sortable: true,
        },
        {
            field: 'valorMedioCompra',
            headerName: 'Valor médio de compra',
            filterable: true,
            sortable: true,
            dataType: 'number',
            valueFormatter: (params) => formatCurrency(params.valorMedioCompra),
        },
        {
            field: 'valorTotalCompras',
            headerName: 'Valor total de compra',
            filterable: true,
            sortable: true,
            dataType: 'number',
            valueFormatter: (params) => formatCurrency(params.valorTotalCompras),
        },
        {
            field: 'tipoCliente',
            headerName: 'Tipo do cliente',
            filterable: false,
            sortable: true,
        },
        {
            field: 'data_criacao',
            headerName: 'Data de criação',
            filterable: true,
            sortable: true,
            dataType: 'date',
            valueFormatter: (params) => {
                return params.data_criacao ? dayjs(params.data_criacao).format("DD/MM/YYYY") : '';
            },
        },
        {
            field: 'hasWhatsApp',
            headerName: 'Vinculada conversa?',
            filterable: true,
            sortable: true,
            dataType: 'boolean',
            valueFormatter: (params) => {
                return params.hasWhatsApp ? 'Sim' : 'Não';
            }
        },
        {
            field: 'lastInteraction.timestamp',
            headerName: 'Última interação',
            sortable: true,
            filterable: false,
            valueFormatter: (params) => {
                return params.lastInteraction ? dayjs.unix(params.lastInteraction.timestamp).format("DD/MM/YYYY HH:mm:ss") : '';
            }
        },
        {
            field: 'lastInteraction.byMe',
            headerName: 'Interação por mim?',
            sortable: true,
            filterable: false,
            dataType: 'boolean',
            valueFormatter: (params) => {
                return params.lastInteraction ? params.lastInteraction.byMe ? 'Sim' : 'Não' : '';
            }
        },

    ];


    const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);

        switch(newTabIndex) {
            case 1:
                handleChangeClienteType('novos');
                break;
            case 2:
                handleChangeClienteType('hot');
                break;
            case 3:
                handleChangeClienteType('ativos');
                break;
            case 4:
                handleChangeClienteType('hibernando');
                break;
            case 5:
                handleChangeClienteType('inativos');
                break;
            case 6:
                handleChangeClienteType('perdidos');
                break;
            default: {
                handleChangeClienteType('todos');
                break;
            }
        }

    };
    const toolTipTexts = {
        'todos': 'Todos os clientes',
        'novos': 'Clientes que ainda não realizaram compra',
        'hot': `Clientes que não compraram nos últimos {periodicidade de compra do cliente} meses
        e compraram nos últimos {periodicidade de compra do cliente}*2 meses`,
        'ativos': 'Clientes que compraram nos últimos {periodicidade de compra do cliente} meses',
        'hibernando': `Clientes que não compraram nos últimos {periodicidade de compra do cliente}*2 meses
        e compraram nos últimos {periodicidade de compra do cliente}*3 meses`,
        'inativos': `Clientes que não compraram nos últimos {periodicidade de compra do cliente}*3 meses
        e compraram nos últimos {periodicidade de compra do cliente}*4 meses`,
        'perdidos': `Clientes que não fizeram uma compra nos últimos {periodicidade de compra do cliente}*4 meses ou mais`,
    };

    const fabStyle = {
        position: 'fixed',
        bottom: 16,
        right: 16,
    };

    //Disparos functions
    const openDisparosDetails = () => {
        console.log(selectedRows);
        setDisparoWindowOpen(true);
    }
    const closeDisparosDetails = () => {
        setDisparoWindowOpen(false);
    }
    const [disparosWindowOpen, setDisparoWindowOpen] = useState(false);

    const [disparoSuccess, setDisparoSuccess] = useState(false);

    const handleClose = (event, reason) => {
        setDisparoSuccess(false);
    }

    const confirmDisparo = () => {
        closeDisparosDetails();
        setDisparoSuccess(true);
    }

    return (
        <Box>
            {error && <Alert severity="error">{errorMessage}</Alert>}
            <Snackbar open={disparoSuccess} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Disparos criados com sucesso.
                </Alert>
            </Snackbar>
            {openClienteDetails && (<ClienteDetails
                open={openClienteDetails}
                onClose={handleCloseClienteDetails}
                cliente={clienteDetails}
            />)}

            {disparosWindowOpen && (<ClienteDisparo
                open={disparosWindowOpen}
                onClose={closeDisparosDetails}
                clientes={selectedRows}
                onConfirmDisparo={confirmDisparo}
            />)}

            {loading && <LinearProgress />}
            {!loading && (
            <Box>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label={
                        <Tooltip title={toolTipTexts.todos}>
                            <span>Todos</span>
                        </Tooltip>
                    } />
                    <Tab label={
                        <Tooltip title={toolTipTexts.novos}>
                            <span>Novos</span>
                        </Tooltip>
                    } />
                    <Tab label={
                        <Tooltip title={toolTipTexts.hot}>
                            <span>Hot</span>
                        </Tooltip>
                    } />
                    <Tab label={
                        <Tooltip title={toolTipTexts.ativos}>
                            <span>Ativos</span>
                        </Tooltip>
                    } />
                    <Tab label={
                        <Tooltip title={toolTipTexts.hibernando}>
                            <span>Hibernando</span>
                        </Tooltip>
                    } />
                    <Tab label={
                        <Tooltip title={toolTipTexts.inativos}>
                            <span>Inativos</span>
                        </Tooltip>
                    } />
                    <Tab label={
                        <Tooltip title={toolTipTexts.perdidos}>
                            <span>Peridos</span>
                        </Tooltip>
                    } />
                </Tabs>

                <Datatable
                    data={clientes}
                    dataTotalCount={totalClientes}
                    columns={columns}
                    onRowClick={handleOpenClienteDetails}
                    sort
                    onSort={handleSearchChange}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20, 30, 50]}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Clientes por página:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                    }}
                    onPagination={handleSearchChange}
                    filter
                    filterPlaceholder="Pesquisar"
                    onFilter={handleSearchChange}
                    searchParams={searchParams}
                    selectableRows
                    onSelect={handleSelect}
                />
            </Box>
            )}
        {selectedRows.length > 0 && 
            <Fab variant="extended" sx={fabStyle} onClick={openDisparosDetails}>
                Criar campanha ({ selectedRows.length })
            </Fab>
        }
        </Box>
    )

}

export default ClientesList;