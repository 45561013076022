import { Box, Typography } from "@mui/material";
import CampanhasList from "../../components/Vendedor/CampanhasList";

const VendedorCampanhas = () => {

    return (
        <Box>
            <Typography variant="h4" component="h1" gutterBottom textAlign='center' mt={2}>
                Campanhas
            </Typography>

            <Box>
                <CampanhasList />
            </Box>
        </Box>
    )

}

export default VendedorCampanhas;