import { useAuth } from "../context/AuthContext";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({children}) => {
    const { user } = useAuth();

    if(!user) return <Navigate to="/login" />;

    if(user.user.role !== 0) {

        if(user.user.role === 1)
            return <Navigate to="/dashboard" />;

        return <Navigate to="/login" />;
    }

    return children;
}

export default ProtectedRoute;