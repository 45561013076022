import Footer from "../components/Footer";
import Header from "../components/Header";
import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import IndicadoresKPI from "../components/Dashboard/Financeiro/IndicadoresKPI";

const Dashboard = () => {

    return (
        <Container component="main" disableGutters >
            <Header />
            <Container component="main" maxWidth="lg" >
                <Typography align={"center"} variant="h2" component="h2" gutterBottom>
                    Dashboard
                </Typography>
                <Box>             
                    <Box spacing={2} >
                        <IndicadoresKPI />
                    </Box>
                    <Box spacing={2}>
                        
                    </Box>
                </Box>
            </Container>
            <Footer />
        </Container>
    );

};

export default Dashboard;