import HttpService from "./HttpService";

const ClientesService = () => {

    const get = (searchParams) => {

        // Faça a chamada à API ou ao banco de dados para obter a lista de clientes com base nos parâmetros de pesquisa
        // Exemplo de chamada à API usando HttpService:
        return HttpService().get("resource/vendedor/clientes", { params: searchParams });
    };

    const getFinanceiro = (searchParams) => {
        return HttpService().get('resource/financeiro/clientes', { params: searchParams });
    }

    const setLembrete = (cliente, mensagem, newState) => {
        return HttpService().post('resource/financeiro/cliente/lembrete', { cliente, mensagem, newState } );
    }

    return {
        get,
        getFinanceiro,
        setLembrete,
    };
};

export default ClientesService;