import HttpService from '../services/HttpService';

const SeedDashboard = async () => {
  try {
    const httpService = HttpService();
    const response = await httpService.get('/resource/vendedor/dashboard');
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error('Erro ao obter os dados do dashboard');
  }
};

export default SeedDashboard;