//A header de usuário logado verifica se o usuário logado é admin, se for, retorna o link de admin, se não, retorna o link de usuário
// Path: react-app\src\components\Header\HeaderLogged.js
import { Link } from 'react-router-dom';
import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

const HeaderLoggedAdmin = () => {

    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuOpen = () => {
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
                >
                <MenuIcon />
                </IconButton>
                <Drawer anchor="left" open={menuOpen} onClose={handleMenuClose}>
                <List className="menu-list">
                    <ListItemButton component={Link} to="/dashboard">
                        <ListItemText primary="Dashboard" />
                    </ListItemButton>
                    {/* <ListItemButton component={Link} to="/metas">
                        <ListItemText primary="Metas" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/vendedores">
                        <ListItemText primary="Vendedores" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/vendas">
                        <ListItemText primary="Vendas" />
                    </ListItemButton> */}
                    <ListItemButton component={Link} to="/clientes">
                        <ListItemText primary="Clientes" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/cobrancas">
                        <ListItemText primary="Cobranças" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/disparos">
                        <ListItemText primary="Disparos" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/logout">
                        <ListItemText primary="Logout" />
                    </ListItemButton>
                </List>
                </Drawer>
                <div style={{ flexGrow: 1 }}>
                <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                    Pro B2B
                </span>
                </div>
                <Link to="/configwhats">
                    <IconButton color="inherit" aria-label="account">
                        <AccountCircle />
                    </IconButton>
                </Link>
            </Toolbar>
        </AppBar>
    );
};

export default HeaderLoggedAdmin;