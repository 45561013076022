import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Box,
  Typography,
  LinearProgress,
  Alert
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const ClienteDisparo = ({ open, onClose, clientes, onConfirmDisparo }) => {
  const [disparoType, setDisparoType] = useState("agora"); // Disparo type: "agora", "daquiTantoTempo", "horarioEspecifico"
  const [mensagem, setMensagem] = useState(""); // Mensagem to be composed
  const [selectedFile, setSelectedFile] = useState(null); // To store the selected file

  const [loading, setLoading] = useState(false); // Loading flag for API call
  const [error, setError] = useState(false); // Error flag for API call
  const [errorMessage, setErrorMessage] = useState(""); // Error message for API call

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleClose = () => {
    setDisparoType("agora");
    setMensagem("");
    setLoading(false);
    setError(false);
    setErrorMessage("");
    onClose();
  };

  const handleConfirmDisparo = () => {
    setLoading(true);
    setError(false);
    setErrorMessage("");

    // Compose the data for the API call
    let data = {
      clientes: clientes,
      disparoType,
      mensagem,
    };

    if (selectedFile) {
      // Convert the image to base64 before sending
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result.split(",")[1];
        data.imagemBase64 = base64Image; // Add the base64 data to the API call data
        sendDisparoData(data);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      // No image selected, just send the data as is
      sendDisparoData(data);
    }

  };
  
  const sendDisparoData = (data) => {
    // You need to replace 'yourAPIEndpoint' with the actual API endpoint for the disparo
    fetch(process.env.REACT_APP_SERVER+"resource/vendedor/disparos", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        // Handle the response from the API if needed
        // For example, show a success message or update the UI
        onConfirmDisparo(); // Notify the parent component about the successful disparo
        handleClose(); // Close the dialog
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        setErrorMessage("Houve um erro ao tentar criar o disparo. Por favor tente novamente.");
      });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="cliente-disparo-dialog-title"
    >
      <DialogTitle id="cliente-disparo-dialog-title">
        Criar novo disparo ({clientes.length})
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* <FormControl fullWidth>
            <InputLabel id="disparo-type-label">Quando disparar</InputLabel>
            <Select
              labelId="disparo-type-label"
              value={disparoType}
              onChange={(e) => setDisparoType(e.target.value)}
            >
              <MenuItem value="agora">Agora</MenuItem>
              <MenuItem value="daquiTantoTempo">Daqui tanto tempo</MenuItem>
              <MenuItem value="horarioEspecifico">Horário específico</MenuItem>
            </Select>
          </FormControl> */}

          {/* {disparoType === "daquiTantoTempo" && (
            <TextField
              label="Tanto tempo (em minutos)"
              type="number"
              fullWidth
              // Add appropriate state and change handlers to capture the time value
            />
          )}

          {disparoType === "horarioEspecifico" && (
            <TextField
              label="Horário específico (HH:mm)"
              fullWidth
              // Add appropriate state and change handlers to capture the time value
            />
          )} */}

          <div style={{ marginTop: "16px" }}>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="image-upload"
            />
            <label htmlFor="image-upload">
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Upload Image (Optional)
              </Button>
            </label>
            {selectedFile && (
              <Typography variant="body2" color="textSecondary" style={{ marginLeft: "8px" }}>
                {selectedFile.name} selected
              </Typography>
            )}
          </div>

          <TextField
            label="Mensagem"
            multiline
            rows={4}
            fullWidth
            value={mensagem}
            onChange={(e) => setMensagem(e.target.value)}
            placeholder="Digite sua mensagem aqui..."
            margin="dense"
          />

          <Box mt={2}>
            <Typography variant="subtitle1">Legenda das variáveis:</Typography>
            {/* Display a legend of variables that can be used in the message */}
            <Typography variant="body2">
              {"{nome}"}  {"{fantasia}"}
              Exemplo: Olá {"{nome}"}! Isto irá inserir o nome do cliente na mensagem.
            </Typography>
          </Box>

          {loading && <LinearProgress />}
          {error && <Alert severity="error">{errorMessage}</Alert>}
        </DialogContentText>
      </DialogContent>

      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleConfirmDisparo} variant="contained" color="primary" disabled={loading}>
          Confirmar
        </Button>
      </Box>
    </Dialog>
  );
};

export default ClienteDisparo;
