import React, { useEffect, useState, useCallback } from 'react';
import { Card, Box, Container, CardContent, Typography } from '@mui/material';
import formatCurrency from '../utils/formatCurrency';

const Brasil = ({
    data,
    sumField,
    totalField,
    total,
    value,
    noTotal,
    formatAsCurrency,
    selectedShowDetailed,
    detailedField,
    bgFillColor,
    mapWidth,
    mapHeight,
    selectedColor,
    strokeColor,
    strokeLinecap,
    strokeLinejoin,
    strokeMiterlimit
}) => {
    const [lastID, setLastID] = useState(null); // Last state clicked
    const [mapData, setMapData] = useState(data);

    //Define a escala de cores de acordo o 'SumField' de cada estado
    const getMaxVal = useCallback(() => {
      return Math.max(...mapData.map((state) => state[sumField]));
    }, [mapData, sumField]);

    const getMinVal = useCallback(() => {
      return Math.min(...mapData.map((state) => state[sumField]));
    }, [mapData, sumField]);

    const colorScale = useCallback((value) => {
      const maxVal = getMaxVal();
      const minVal = getMinVal();
      const scale = (maxVal - minVal) / 255;
      const transparentScale = maxVal + minVal;

      if(!value){
        value = minVal/2;
      }

      const transparentColor = `rgba(0, 0, ${Math.floor(255 - value / scale)}, ${value/transparentScale})`;
      return transparentColor;
    }, [getMaxVal, getMinVal,]);

    useEffect(() => {
      setMapData((prevState) => {
        return prevState.map((state) => {
          state.color = colorScale(state[sumField]);
          return state;
        });
      }); // eslint-disable-next-line
    }, []);

    const getStateColorFromData = (stateName) => {
      const state = data.find((state) => state.nome === stateName);

      if(!state || state === null){
        const maxVal = getMaxVal();
        const minVal = getMinVal();
        const scale = (maxVal - minVal) / 255;
        const transparentScale = maxVal + minVal;
        const value = minVal/2

        return `rgba(0, 0, ${Math.floor(255-value / scale)}, ${value/transparentScale})`
      }

      return colorScale(state[sumField]);
    };

    //Pega a cor de fundo para um estado específico
    const getStateColor = (stateName) => {
      const state = mapData.find((state) => state.nome === stateName);

      if(!state || state === null){
        const maxVal = getMaxVal();
        const minVal = getMinVal();
        const scale = (maxVal - minVal) / 255;
        const transparentScale = maxVal + minVal;
        const value = minVal/2;

        return `rgba(0, 0, ${Math.floor(255-value / scale)}, ${value/transparentScale})`
      }
      return state.color;
    };

    const handleMouseEnter = (e) => {
        const { id } = e.target.dataset;
        //Seta a cor de fundo do estado no mapData
        setMapData((prevState) => {
          return prevState.map((state) => {
            if(state.nome === id){
              state.color = selectedColor;
            }
            return state;
          });
        });

    };

    const handleMouseLeave = (e) => {
        const { id } = e.target.dataset;
        if(id === lastID) return; // Se ele estiver selecionado ele não perde a cor
        //Seta a cor de fundo do estado no mapData
        setMapData((prevState) => {
          return prevState.map((state) => {
            if(state.nome === id){
              state.color = getStateColorFromData(id);
            }
            return state;
          });
        });
    };

    const handleOnClick = (e) => {
        const { id } = e.target.dataset;

        if(lastID === id){
          setLastID(null);
          return;
        }

        if(lastID){
          //Seta a cor de fundo do estado no mapData
          setMapData((prevState) => {
            return prevState.map((state) => {
              if(state.nome === lastID){
                state.color = getStateColorFromData(lastID);
              }
              return state;
            });
          });
        }

        setLastID(id);
    }

    return (
      <Container>
        <Box display="flex" justifyContent="center">
          <Box>
            <svg style={{ cursor: 'pointer'}} className="wrapperCity" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" fill={bgFillColor} width={`${mapWidth}px`} height={`${mapHeight}px`} viewBox="0 0 353.845 367.766">

                <g>
              <polygon
                stroke={strokeColor}
                strokeLinecap={strokeLinecap}
                strokeLinejoin={strokeLinejoin}
                strokeMiterlimit={strokeMiterlimit}
                points="
            319.646,163.646 319.099,164.092 320.047,163.646 320.246,163.147 319.696,162.899     "
              />
              <g>
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                262.933,59.372 262.634,58.974 262.284,59.77 262.385,60.018"
                />

                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                260.837,59.72 260.486,59.57 260.137,60.118 260.387,60.217"
                />

                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                268.775,70.112 269.623,69.864 269.923,69.565 270.521,69.019 270.223,68.67 270.422,68.024 270.072,67.875 269.523,68.372
                268.075,68.621 267.776,69.267 268.325,70.063"
                />

                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                266.977,69.715 266.478,70.709 266.878,71.256 266.429,71.853 266.627,72.002 267.525,71.206 267.576,70.709 267.276,70.46
                267.326,69.963"
                />

                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                273.268,67.129 272.519,67.328 272.469,67.676 273.217,67.477"
                />

                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                273.168,66.682 273.667,66.582 273.667,66.283 272.868,66.532 272.469,66.98 273.168,66.98"
                />

                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                287.396,71.455 287.296,71.206 286.897,71.306 286.947,71.555"
                />

                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                317.301,166.48 317.699,166.778 317.999,166.629 317.949,165.982 317.699,166.331"
                />

                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                271.786,256.412 269.955,256.744 270.322,257.207 272.984,256.182 272.719,255.781"
                />

                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                269.523,257.273 268.658,257.439 268.392,258.037 268.126,258.068 268.126,258.5 269.89,258.068 270.488,258.137 270.521,257.805
                270.022,257.738"
                />

                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                261.137,264.436 260.97,263.738 259.805,263.539 259.705,264.07 258.439,265.693 258.807,266.357 260.072,265.629
                260.403,265.992 261.069,265.662 260.004,264.768 260.67,264.799"
                />

                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                231.231,288.252 230.582,288.055 230.383,288.602 229.535,289.297 230.333,290.342 230.582,290.143 230.482,289.744
                231.082,289.197"
                />

                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                232.43,280.396 232.38,280.943 232.829,280.943 232.829,280.148"
                />

                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                235.924,277.463 236.124,277.512 236.474,276.518 238.271,275.125 236.273,276.418"
                />

                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                232.13,299.988 231.682,300.436 231.032,300.635 231.432,301.877 230.782,302.822 230.981,303.32 230.633,304.363
                231.731,303.369 231.58,302.922 232.38,301.828 232.031,301.332 233.029,300.686"
                />

                <polygon
                  data-id="MG"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('MG')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                248.729,178.317 247.68,179.172 248.918,181.266 248.062,182.216 246.159,181.266 244.353,183.167 246.159,184.404
                244.826,185.83 246.159,187.733 245.303,189.541 243.876,190.873 242.067,192.205 241.782,193.916 240.261,194.393
                239.975,196.01 242.258,197.816 242.163,200.102 240.545,202.004 239.118,202.383 239.118,204.764 241.498,203.811
                242.067,205.809 239.784,207.713 240.83,209.328 240.07,211.518 235.883,214.467 233.98,213.516 229.129,212.943 225.704,215.799
                218.092,217.797 214.478,218.938 211.147,222.84 209.815,227.217 208.959,230.164 214.001,228.549 217.711,228.645
                220.851,229.689 222.183,231.688 229.795,232.543 235.503,230.736 241.688,230.928 241.688,232.447 242.733,234.637
                242.163,237.967 243.685,240.346 247.301,241.391 248.156,245.293 246.92,249.383 249.107,254.141 254.436,251.857
                254.816,249.764 258.433,249.191 260.715,250.906 265.853,249.383 273.272,247.385 278.269,247.242 282.265,244.531
                284.119,245.814 286.118,244.104 286.118,240.965 288.259,239.25 286.831,237.537 287.545,236.396 290.874,235.969
                290.685,230.928 291.35,230.832 295.061,230.164 296.012,225.979 298.866,223.6 298.486,220.461 296.772,218.844 296.108,216.654
                298.01,214.943 297.249,212.373 298.392,211.424 300.484,212.088 301.911,211.328 300.77,209.9 300.389,208.189 305.525,209.9
                306.668,207.902 305.146,206.189 303.195,206.666 302.243,202.193 303.456,198.84 305.645,198.555 306.097,196.865
                305.43,195.629 307.619,192.68 306.572,190.018 302.768,189.73 302.102,188.018 298.676,186.972 298.296,188.398 295.537,188.209
                294.396,186.496 294.396,185.449 289.923,181.835 286.402,183.453 283.93,180.694 281.171,179.458 280.79,177.651 277.649,176.89
                275.177,178.602 272.988,177.84 272.132,175.558 269.277,174.893 264.615,176.605 264.141,178.602 259.384,180.409
                256.909,182.693 255.293,182.501 252.914,184.023 251.582,183.548 252.628,181.266 251.012,180.79"
                />

                <polygon
                  data-id="GO"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('GO')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                237.787,165.188 234.456,165.283 233.886,163.38 229.033,165.093 227.987,160.526 224.942,160.24 222.088,164.902
                215.523,162.143 215.904,157.576 213.525,161.787 211.718,164.997 212.003,166.996 210.291,168.898 210.386,172.037
                208.77,174.606 209.244,177.747 206.2,182.122 204.297,181.551 202.299,183.453 202.013,186.876 201.158,188.686 197.352,189.256
                195.448,191.252 194.117,192.395 194.021,195.059 190.692,195.916 190.216,197.912 190.407,198.959 188.123,200.006
                187.743,202.193 188.218,206.379 189.359,207.426 189.359,208.854 188.028,209.9 188.693,212.66 190.026,211.898 191.453,213.135
                191.644,215.514 196.923,216.703 199.635,219.271 203.346,219.414 206.058,222.125 211.147,222.84 214.478,218.938
                218.092,217.797 225.704,215.799 229.129,212.943 233.98,213.516 235.883,214.467 240.07,211.518 240.83,209.328 239.784,207.713
                242.067,205.809 241.498,203.811 239.118,204.764 239.118,202.383 240.545,202.004 242.163,200.102 242.258,197.816
                239.975,196.01 240.261,194.393 241.782,193.916 242.067,192.205 243.876,190.873 233.791,190.873 233.695,186.876
                234.933,185.83 235.122,183.833 241.021,183.357 243.685,184.499 243.781,187.447 243.876,190.873 245.303,189.541
                246.159,187.733 244.826,185.83 246.159,184.404 244.353,183.167 246.159,181.266 248.062,182.216 248.918,181.266
                247.68,179.172 248.729,178.317 251.012,180.79 252.628,181.266 253.579,179.458 253.199,178.221 253.961,176.319
                253.484,174.606 251.296,174.321 251.678,172.229 250.059,170.325 250.726,167.947 252.438,166.615 251.391,164.902
                253.009,163.571 252.533,161.953 250.819,161.953 243.971,162.904"
                />

                <polygon
                  data-id="TO"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('TO')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                249.87,134.838 247.396,133.221 248.252,132.079 245.873,130.557 245.303,128.083 243.21,127.513 243.21,125.991 245.017,125.991
                244.541,122.564 245.493,121.709 249.107,122.755 249.298,121.233 246.92,120.091 248.347,117.333 246.635,116.476
                244.353,118.949 243.02,116.476 241.4,115.334 239.213,114.192 238.166,112.29 238.548,108.389 238.071,101.919 235.694,96.307
                232.363,95.736 229.675,97.043 232.03,99.042 232.03,103.109 231.459,106.462 228.748,108.674 225.181,109.316 223.61,110.387
                223.683,111.244 224.823,113.099 223.325,117.166 223.325,118.593 224.966,122.446 220.542,129.582 218.259,132.222
                216.618,135.076 216.118,137.788 214.834,142.497 212.407,145.708 212.052,148.349 211.053,151.345 211.908,153.343
                212.193,155.341 211.766,157.053 212.621,159.194 213.525,161.787 215.904,157.576 215.523,162.143 222.088,164.902
                224.942,160.24 227.987,160.526 229.033,165.093 233.886,163.38 234.456,165.283 237.787,165.188 243.971,162.904
                250.819,161.953 250.535,160.811 251.582,159.956 249.488,156.815 250.345,155.579 252.628,154.151 252.533,152.439
                251.012,153.01 250.63,151.868 251.867,151.107 251.772,149.681 250.726,149.204 249.298,149.3 247.586,147.396 248.632,145.779
                250.535,144.066 250.059,141.117 253.866,140.261 254.722,138.643 250.819,137.312"
                />

                <polygon
                  data-id="RJ"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('RJ')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                293.158,239.49 290.874,235.969 287.545,236.396 286.831,237.537 288.259,239.25 286.118,240.965 286.118,244.104
                284.119,245.814 282.265,244.531 278.269,247.242 273.272,247.385 265.853,249.383 265.853,252.998 262.428,257.945
                263.199,259.527 263.798,260.291 266.761,260.059 267.027,259.23 268.092,259.164 267.027,258.633 266.062,258.898
                265.497,258.533 266.095,258.068 265.129,258.17 265.463,257.207 266.661,255.781 268.392,255.75 268.891,256.412
                267.859,257.439 269.423,256.711 270.555,255.617 272.852,255.584 273.252,256.115 275.314,256.08 276.413,256.428
                277.262,256.381 277.811,255.732 277.61,255.236 277.711,254.639 279.259,253.545 279.808,254.092 279.708,255.336
                278.809,255.732 278.959,255.982 280.207,256.131 284.8,256.428 286.696,256.381 286.547,255.982 284.8,256.281 282.653,255.883
                284.501,255.186 286.696,255.285 286.797,255.781 288.495,255.832 289.044,256.031 290.393,254.59 289.843,253.893
                289.743,252.301 291.89,250.113 295.533,249.367 298.679,247.129 297.931,245.092 298.38,241.711 296.393,239.203"
                />

                <polygon
                  data-id="SP"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('SP')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                265.853,252.998 265.853,249.383 260.715,250.906 258.433,249.191 254.816,249.764 254.436,251.857 249.107,254.141
                246.92,249.383 248.156,245.293 247.301,241.391 243.685,240.346 242.163,237.967 242.733,234.637 241.688,232.447
                241.688,230.928 235.503,230.736 229.795,232.543 222.183,231.688 220.851,229.689 217.711,228.645 214.001,228.549
                208.959,230.164 208.389,231.688 204.772,235.875 204.394,237.775 203.44,239.393 201.252,243.865 197.828,247.385
                194.213,249.859 190.407,253.475 193.356,254.141 196.591,253.949 199.635,253.189 201.158,252.809 202.109,254.33
                203.061,254.33 207.056,254.33 212.384,257.186 218.663,256.424 220.945,258.326 220.757,263.463 220.375,266.127
                221.898,269.363 224.942,269.172 228.368,269.744 229.318,273.357 232.173,276.213 235.218,274.689 234.456,277.734
                236.125,278.635 236.175,277.912 235.525,278.16 235.725,276.717 237.172,275.225 238.321,274.877 240.717,273.135
                240.418,272.887 242.614,271.895 244.861,269.557 246.392,267.848 247.823,266.623 248.854,266.588 249.787,265.895
                250.719,266.623 251.318,266.656 251.717,265.297 253.548,263.738 256.443,263.771 257.541,264.436 257.608,264.734
                258.474,264.832 258.606,264.336 259.072,264.234 259.339,263.572 258.807,263.539 258.74,262.977 259.506,262.082
                260.57,262.182 261.302,260.988 263.199,259.527 262.428,257.945"
                />

                <polygon
                  data-id="BA"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('BA')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                327.932,147.063 324.364,145.493 326.077,143.068 327.932,142.497 328.217,139.5 324.221,137.646 324.078,136.075
                325.648,134.363 324.364,131.508 323.079,128.797 321.653,127.37 319.654,127.227 319.226,125.799 317.514,125.799
                315.372,123.944 312.519,124.23 310.521,125.799 308.809,126.085 308.095,128.511 305.812,130.794 303.527,131.081
                301.815,130.366 301.531,128.654 299.104,128.083 299.818,125.799 297.963,124.087 295.061,124.468 292.683,126.085
                292.492,127.227 290.113,127.513 289.352,129.416 286.879,130.271 285.167,130.271 283.643,131.603 281.265,131.603
                278.602,129.225 277.46,130.652 275.177,128.939 273.368,130.652 273.559,132.841 274.796,133.982 274.321,136.36
                272.513,137.501 272.132,139.69 270.989,139.5 269.944,141.022 267.185,140.832 265.376,142.83 260.145,143.116 257.576,139.024
                254.722,138.643 253.866,140.261 250.059,141.117 250.535,144.066 248.632,145.779 247.586,147.396 249.298,149.3
                250.726,149.204 251.772,149.681 251.867,151.107 250.63,151.868 251.012,153.01 252.533,152.439 252.628,154.151
                250.345,155.579 249.488,156.815 251.582,159.956 250.535,160.811 250.819,161.953 252.533,161.953 253.009,163.571
                251.391,164.902 252.438,166.615 250.726,167.947 250.059,170.325 251.678,172.229 251.296,174.321 253.484,174.606
                253.961,176.319 253.199,178.221 253.579,179.458 252.628,181.266 251.582,183.548 252.914,184.023 255.293,182.501
                256.909,182.693 259.384,180.409 264.141,178.602 264.615,176.605 269.277,174.893 272.132,175.558 272.988,177.84
                275.177,178.602 277.649,176.89 280.79,177.651 281.171,179.458 283.93,180.694 286.402,183.453 289.923,181.835 294.396,185.449
                294.396,186.496 295.537,188.209 298.296,188.398 298.676,186.972 302.102,188.018 302.768,189.73 306.572,190.018
                307.619,192.68 305.43,195.629 306.097,196.865 305.645,198.555 303.456,198.84 302.243,202.193 303.195,206.666 305.146,206.189
                306.668,207.902 305.525,209.9 310.517,213.857 311.16,210.732 314.305,208.146 315.453,206.107 314.505,204.965 314.754,202.627
                315.403,200.736 315.304,197.703 316.353,194.371 316.202,192.631 317.201,190.742 317.051,188.156 316.452,186.764
                316.202,185.223 316.702,183.036 316.402,182.788 316.702,182.142 317.051,182.441 317.301,181.892 316.751,181.645
                316.852,180.998 316.152,179.059 316.054,177.319 316.801,175.729 317.201,171.701 317.85,169.313 317.449,169.414
                317.001,170.955 316.702,170.806 316.801,169.911 317.151,169.612 316.801,169.463 317.25,169.065 316.852,168.867
                317.25,167.922 317.899,168.668 318.099,168.419 317.65,167.275 316.952,167.374 316.901,165.982 317.449,166.131
                317.301,165.286 318.299,164.789 318.148,164.391 319.497,163.248 318.948,163.347 318.849,162.701 319.297,162.651
                319.447,161.955 318.799,161.855 318.898,161.357 318.148,161.309 318.299,161.01 319.148,161.06 319.398,160.065
                320.097,160.115 320.146,160.91 320.296,161.159 320.795,160.612 320.844,161.159 321.595,161.159 321.295,161.905
                320.895,161.855 320.746,163.198 322.043,162.899 325.887,159.17 327.867,156.004 330.098,152.474 329.698,152.208
                329.632,151.214 329.964,151.082 330.098,151.745 330.363,151.811 331.163,149.688 330.63,149.49 330.563,148.894
                327.932,148.918"
                />

                <path
                  data-id="MT"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('MT')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  d="M156.346,203.24
                l1.142,1.141l3.33-1.711l3.33,0.477l1.617,1.711l4.758,1.713l1.903-0.191l2.949-1.998l2.474,0.762l2.379-0.285l2.378-2.664
                l1.713,0.381l0.38,1.047l-0.286,3.426l1.522,1.711l2.094,1.143l1.331-1.047v-1.428l-1.142-1.047l-0.475-4.186l0.38-2.188
                l2.284-1.047l-0.191-1.047l0.477-1.996l3.329-0.857l0.096-2.664l1.331-1.143l1.903-1.996l3.807-0.57l0.854-1.809l0.286-3.423
                l1.998-1.902l1.903,0.571l3.044-4.375l-0.475-3.14l1.616-2.569l-0.095-3.139l1.712-1.902l-0.285-1.999l1.808-3.21l-0.904-2.593
                l-0.855-2.141l0.428-1.712l-0.285-1.998l-0.855-1.998l0.999-2.996l0.355-2.641l2.427-3.211l1.284-4.709l0.5-2.711l-57.941-0.143
                l-3.996-1.142l-3.854-2.284l-2.854-4.423l-1.854-4.71c0,0-1-1.284-1.142-1.712c-0.143-0.429-0.571-2.854-0.571-2.854l-1.998-1.427
                l-0.524,1.617l0.762,3.044l-1.333,2.093l-0.665,2.283l-26.069,0.952l-1.142,2.71l1.57,3.283l-1.57,5.708l0.429,4.282l2.712,0.855
                l1.284,2.283l4.567-0.713l4.424,0.857l0.857,3.567l-1.571,2.569l2.712,5.708l-1.713,1.428l0.572,2.569l-3.141,0.999l0.571,3.853
                l-2.459,3.379l1.264,5.025v8.153l-2.098,0.398l3.995,8.75l2.696,1.094l4.892,0.598l3.695-1.293l5.393,1.193l-0.5,1.988
                l1.298,8.951l2.495,0.596l3.933,5.533l3.178-2.793L156.346,203.24z"
                />

                <polygon
                  data-id="RS"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('RS')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                215.714,320.738 214.667,320.357 217.235,316.076 219.899,313.508 219.71,311.795 218.378,310.844 216.569,311.32
                214.478,312.271 213.052,312.271 211.053,310.463 209.054,306.941 207.342,303.613 205.153,302.566 201.348,301.615
                198.873,300.379 196.211,298.762 192.976,298.191 188.98,298.191 185.364,298.57 183.62,298.275 171.023,304.363 165.032,310.133
                159.191,319.432 151.903,325.398 150.604,328.383 155.53,328.646 158.924,331.697 161.122,332.293 161.988,334.215
                163.452,335.875 163.384,336.668 164.583,337.729 165.981,336.934 166.381,335.875 168.843,335.807 171.04,338.459
                180.825,347.012 183.354,347.674 189.013,354.637 186.883,359.477 187.082,362.857 185.951,366.57 186.449,367.764
                187.647,364.881 192.641,360.205 195.536,356.029 197.033,350.758 200.129,348.77 199.829,348.371 198.532,348.869
                198.432,347.773 199.629,347.477 197.934,346.879 198.232,346.082 199.23,345.984 199.829,344.988 200.129,342.803
                201.527,341.707 202.725,341.906 203.125,340.613 203.724,340.912 203.923,338.027 205.021,336.535 205.121,337.631
                205.722,335.74 206.818,334.547 206.818,333.752 205.121,333.156 205.021,332.559 205.621,331.762 206.021,332.758
                207.019,333.254 208.616,333.156 209.614,331.861 211.612,331.166 212.012,333.254 211.312,333.553 211.212,332.857
                210.612,332.758 210.812,336.535 209.314,337.232 209.216,338.027 208.117,338.525 207.917,341.111 206.52,342.305
                205.321,342.404 203.623,345.189 202.825,345.189 202.226,346.184 200.629,345.785 200.329,346.283 201.028,347.277
                200.43,348.172 200.828,348.57 201.327,347.477 204.822,345.389 211.312,338.824 216.604,326.889 218.351,320.922
                218.663,320.547 217.33,319.502"
                />

                <polygon
                  data-id="SC"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('SC')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                224.823,285.988 220.686,288.344 217.401,286.346 214.478,286.844 212.052,286.273 207.199,289.414 205.985,291.197
                206.058,292.98 204.844,293.695 199.706,292.695 196.425,291.34 193.927,291.41 192.356,289.484 189.502,290.197 186.648,288.984
                183.603,290.621 185.153,294.27 183.62,298.275 185.364,298.57 188.98,298.191 192.976,298.191 196.211,298.762 198.873,300.379
                201.348,301.615 205.153,302.566 207.342,303.613 209.054,306.941 211.053,310.463 213.052,312.271 214.478,312.271
                216.569,311.32 218.378,310.844 219.71,311.795 219.899,313.508 217.235,316.076 214.667,320.357 215.714,320.738 217.33,319.502
                218.663,320.547 226.838,312.867 227.688,313.016 228.336,312.471 227.937,311.176 228.186,310.182 229.285,309.934
                229.435,308.641 229.885,308.59 229.535,310.133 229.834,309.982 230.282,307.746 230.333,305.807 230.184,305.605
                230.433,304.465 229.834,302.873 230.033,301.68 230.433,301.23 229.834,300.834 229.885,300.039 230.732,299.691
                230.832,299.393 230.533,298.893 231.432,298.348 231.932,298.646 232.33,298.1 231.381,297.203 231.032,297.75 230.533,297.551
                230.732,296.756 230.184,296.707 230.033,295.762 230.533,294.867 229.783,294.619 229.634,292.777 230.083,290.689
                229.285,289.496 228.535,289.695 227.786,289.545 228.685,288.998 227.837,287.955 229.034,288.75 230.233,287.793
                228.248,287.129"
                />
                <polygon
                  data-id="PR"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('PR')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                234.456,277.734 235.218,274.689 232.173,276.213 229.318,273.357 228.368,269.744 224.942,269.172 221.898,269.363
                220.375,266.127 220.757,263.463 220.945,258.326 218.663,256.424 212.384,257.186 207.056,254.33 203.061,254.33 202.109,254.33
                201.158,252.809 199.635,253.189 196.591,253.949 193.356,254.141 190.407,253.475 186.887,256.709 185.364,259.564
                184.604,263.369 183.556,265.082 180.196,265.885 177.763,274.082 177.664,278.756 176.28,281.801 182.956,283.033
                184.454,288.203 183.603,290.621 186.648,288.984 189.502,290.197 192.356,289.484 193.927,291.41 196.425,291.34
                199.706,292.695 204.844,293.695 206.058,292.98 205.985,291.197 207.199,289.414 212.052,286.273 214.478,286.844
                217.401,286.346 220.686,288.344 224.823,285.988 228.248,287.129 230.233,287.793 230.184,284.873 229.085,284.475
                230.033,284.375 231.132,282.336 232.28,281.49 231.682,281.291 231.082,281.49 231.032,280.943 229.585,281.043 229.085,281.143
                229.385,280.695 228.485,279.9 229.934,280.396 230.482,280.098 230.932,280.695 231.631,279.6 231.381,278.807 231.932,279.004
                232.33,278.705 232.28,279.502 232.879,279.9 233.478,279.451 233.777,279.65 233.029,280.297 233.129,281.143 233.528,281.094
                234.377,279.65 236.125,278.635"
                />

                <polygon
                  data-id="MS"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('MS')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                184.604,263.369 185.364,259.564 186.887,256.709 190.407,253.475 194.213,249.859 197.828,247.385 201.252,243.865
                203.44,239.393 204.394,237.775 204.772,235.875 208.389,231.688 208.959,230.164 209.815,227.217 211.147,222.84
                206.058,222.125 203.346,219.414 199.635,219.271 196.923,216.703 191.644,215.514 191.453,213.135 190.026,211.898
                188.693,212.66 188.028,209.9 185.935,208.758 184.413,207.047 184.698,203.621 184.318,202.574 182.605,202.193 180.227,204.857
                177.848,205.143 175.375,204.381 172.426,206.379 170.522,206.57 165.765,204.857 164.148,203.146 160.818,202.67
                157.488,204.381 156.346,203.24 152.922,207.902 149.744,210.695 144.914,224.754 146.21,228.334 144.714,231.219 146.012,232.91
                147.909,237.584 145.413,246.336 146.411,248.82 149.407,249.219 160.988,248.623 163.485,249.518 165.182,248.721
                167.679,250.811 168.976,265.23 173.371,266.027 175.966,263.938 180.196,265.885 183.556,265.082"
                />

                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                157.108,70.571 159.676,67.574 161.817,63.864 159.534,63.864 156.251,66.717 154.681,68.573"
                />

                <polygon
                  data-id="PI"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('PI')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                303.146,108.199 302.481,105.345 299.818,105.345 298.961,100.017 299.247,97.543 297.915,96.401 297.724,94.308 298.392,93.167
                296.679,91.55 296.012,87.649 297.629,84.89 296.772,80.132 294.585,78.516 294.3,76.422 295.833,73.344 292.888,73.146
                290.791,72.798 289.193,71.555 289.543,73.71 289.114,76.708 283.691,80.561 280.694,84.7 278.696,88.694 279.41,90.693
                281.552,92.834 281.836,94.975 277.84,99.255 278.125,101.396 279.98,104.536 278.696,108.104 277.555,110.245 276.27,110.387
                272.132,108.532 269.991,109.246 267.707,112.813 260.715,116.952 256.434,119.663 256.007,121.947 256.149,126.371
                253.866,128.654 253.295,131.508 255.862,134.648 254.722,138.643 257.576,139.024 260.145,143.116 265.376,142.83
                267.185,140.832 269.944,141.022 270.989,139.5 272.132,139.69 272.513,137.501 274.321,136.36 274.796,133.982 273.559,132.841
                273.368,130.652 275.177,128.939 277.46,130.652 278.602,129.225 281.265,131.603 283.643,131.603 285.167,130.271
                286.879,130.271 289.352,129.416 290.113,127.513 292.492,127.227 292.683,126.085 295.061,124.468 296.012,122.375
                300.675,120.281 302.102,118.949 301.72,114.573 300.579,111.909 302.006,110.577"
                />

                <polygon
                  data-id="MA"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('MA')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                253.295,131.508 253.866,128.654 256.149,126.371 256.007,121.947 256.434,119.663 260.715,116.952 267.707,112.813
                269.991,109.246 272.132,108.532 276.27,110.387 277.555,110.245 278.696,108.104 279.98,104.536 278.125,101.396 277.84,99.255
                281.836,94.975 281.552,92.834 279.41,90.693 278.696,88.694 280.694,84.7 283.691,80.561 289.114,76.708 289.543,73.71
                289.193,71.555 287.646,71.157 287.895,71.853 286.847,71.952 286.547,71.405 285.948,71.753 284.351,71.504 283.353,70.511
                280.956,69.465 277.861,67.676 276.062,67.477 275.913,67.825 276.612,68.173 276.514,68.421 275.913,68.173 276.112,68.67
                275.464,68.72 275.265,67.975 275.114,68.173 275.165,69.068 274.316,68.969 273.217,68.57 273.566,67.925 273.217,67.825
                272.868,68.621 272.719,67.975 272.318,68.919 271.919,68.969 271.071,70.212 269.674,71.057 269.374,70.66 269.123,70.858
                268.924,70.66 268.575,70.958 268.626,70.41 268.274,70.361 267.801,71.629 268.301,73.071 268.075,73.271 267.127,73.221
                266.578,74.787 266.752,72.772 265.654,71.579 265.479,71.107 265.93,70.66 266.179,69.167 266.927,68.173 266.179,67.676
                266.278,67.427 266.977,67.726 267.227,67.328 266.878,66.134 266.079,65.587 265.579,65.737 265.979,66.333 265.18,65.936
                264.281,66.582 264.581,67.129 264.132,67.129 263.781,66.433 263.233,66.283 264.382,65.936 264.231,65.737 265.979,64.991
                265.829,64.643 265.08,64.792 265.629,64.394 264.98,63.399 264.531,63.599 264.231,63.3 264.729,62.802 263.882,62.853
                263.683,62.057 264.332,61.858 264.132,61.46 263.434,61.261 263.732,60.416 263.283,59.968 262.584,60.714 262.233,60.118
                261.735,60.665 261.437,60.465 261.535,60.118 260.837,60.366 260.137,61.112 259.838,60.764 259.588,61.361 258.89,61.212
                258.59,61.709 258.89,62.355 258.49,62.604 259.438,63.35 257.941,62.604 258.49,62.255 258.342,61.809 257.691,61.809
                257.541,60.963 258.391,60.465 258.54,59.57 258.092,59.173 259.239,58.676 259.139,58.179 257.941,58.477 257.941,58.875
                256.692,59.074 256.044,58.875 255.895,58.129 255.045,57.682 254.146,58.228 253.298,57.78 251.867,58.012 251.154,58.797
                250.012,61.937 248.729,66.219 246.943,70.286 245.73,71.712 242.234,75.281 241.378,76.565 241.235,77.349 241.734,80.061
                241.378,81.988 238.095,86.34 236.026,89.267 232.601,92.263 228.32,92.976 227.82,94.118 229.675,97.043 232.363,95.736
                235.694,96.307 238.071,101.919 238.548,108.389 238.166,112.29 239.213,114.192 241.4,115.334 243.02,116.476 244.353,118.949
                246.635,116.476 248.347,117.333 246.92,120.091 249.298,121.233 249.107,122.755 245.493,121.709 244.541,122.564
                245.017,125.991 243.21,125.991 243.21,127.513 245.303,128.083 245.873,130.557 248.252,132.079 247.396,133.221 249.87,134.838
                250.819,137.312 254.722,138.643 255.862,134.648"
                />

                <path
                  data-id="PA"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('PA')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  d="M228.32,92.976
                l4.28-0.713l3.426-2.996l2.068-2.926l3.283-4.352l0.356-1.927l-0.499-2.712l0.143-0.784l0.856-1.284l3.496-3.568l1.213-1.427
                l1.785-4.067l1.283-4.282l1.143-3.14l0.713-0.785l1.431-0.232l0.351-0.845l-0.25-0.696l-0.699-0.199l-1.298,0.397l-0.749-0.944
                l-0.599-0.249l-1.599,0.945l-0.848-0.199l-0.101-0.597l-0.6-0.795h-0.798l-0.201-0.596l-0.348-0.1l-0.85,0.398l-0.448-0.796
                l-1.149,0.15v-0.398l-1.249,0.05v-0.547l-0.398-0.149l-0.449,0.398l-0.35-0.199l-0.05-0.498h-0.448l-0.65,0.498l-0.898,0.199
                l-0.349-0.747l-0.35,0.1l0.05,0.647l-0.5-0.1V52.41l-0.499-0.248l-0.35,0.448l0.3,0.348l-0.699-0.199l-0.199-0.448l-0.499,0.099
                l-0.101,0.547l-0.897-0.348l-1.1,0.746l0.101,0.398l-0.399-0.149l-0.849,0.646l-0.599,0.995l0.35,1.193l-0.899-0.646l-0.699,0.746
                l0.05,0.547l0.398,0.398l-0.448,0.099l-0.249,1.243l0.35,0.846l0.599,0.199l1.598,0.646l-1.598-0.397l-1.148,0.497l-0.05,0.349
                l1.447,1.193l-1.796-1.243l0.049-0.696l-0.6,0.248l-0.249-0.596h-0.399l-0.2,0.696l-0.998,0.498l0.15-0.597l-0.399-0.049
                l-1.798,1.591l-0.648,2.162l-1.748,1.792l0.349,0.82l-1.097,0.497l0.35,1.642l-0.849,0.795v1.392l-0.601-1.542l0.7-0.745
                l-0.4-1.89l2.472-3.182l0.074-1.293l-0.398-0.348l-2.347,1.144l-0.499-0.448l-1.598,0.05l-0.998,0.747l-1.896,0.248l-1.049-0.398
                l-1.199-0.298l-0.349,0.448l0.748,1.441l-0.548-0.498l-0.699,0.349l-0.102,0.995l-0.148-1.243l0.499-0.199v-0.597l-2.146-0.298
                l-2.047,0.695l0.049,2.138l-0.549-2.188l0.65-0.547l-0.898-0.198l1.846-0.746l0.299-0.895l0.85,0.547l0.101,0.746l1.147,0.298
                l0.15-0.696l1.048-0.299l0.2,0.397l1.397,0.249l0.35-0.348l0.1-0.498l0.4-0.298v0.497l1.098,0.149l0.349-0.298l-0.15-0.596
                l0.15-0.299l0.2,0.448l0.999,0.596l1.099-0.348l0.249-0.945l0.1,0.995l2.246-0.746l-0.099-0.497l0.848,0.199l1.849-0.845
                l-0.102-0.298l0.599,0.149l1.898-0.696l0.648-2.138l-0.05-0.696l-0.499,0.199l0.749-0.597l0.599-0.249l-0.15-1.094l0.851-0.697
                l0.149-1.989l0.947-1.94v-0.894l-0.947-0.299l-2.396-0.199l-1.498-1.144l-1.397,0.398l-4.145,0.82l-4.394-0.597l-0.399,0.522
                l-0.648-0.248l-1.897,1.491l0.049,0.747l-0.397-0.547l-0.749,1.591v2.834l0.248,0.349l-0.349,0.05l-0.698,0.945l-0.4,1.641
                l1.798,0.099l-0.948,0.199l0.648,0.348l-1.423-0.249l0.025,1.541l-0.699-1.69l0.4-1.144l-0.3-0.796l-1.398,0.149l-0.1,0.497
                l-2.047,0.449l-1.399,1.342l-2.395,0.746l-0.499,0.547l-2.098,1.243l-1.847,0.299l-0.249,0.696l-1.149-0.049l-0.648,0.447
                l-0.767,2.502l0.301,0.812l0.998,3.48l-0.399,0.598l0.1,0.546l-0.398-0.646l0.199-0.498l-1.598-3.033l0.399-2.917l1.016-1.441
                l0.698-0.299l0.85,0.199l0.05-0.447l-0.85-0.398l-2.397,0.298l-1.047,0.348l-1.398-0.099l-1.198,0.944l0.051,0.498l-1.048,0.049
                l-2.597,0.946h-0.301l0.5-0.448l2.197-0.795l0.798-1.144l0.948-0.647l2.297,0.15l0.749-1.193l1.997,0.149l1.648-0.646
                l-0.517-0.646l0.019-0.01l-2.566-0.17l-0.571-1.332l0.762-1.332l-0.38-1.333l-1.143-0.951l-3.044-3.235l-3.045-3.615l-0.381-4.376
                l-1.521-0.952l-2.475-1.712l-0.76-3.425l-8.182-2.474l-0.951-3.234l-0.209-4.816l-1.696,0.249l-1.148-1.194l-2.545,1.292
                l-1.997,0.398l-2.546-0.945l-0.999,0.995l0.45,1.492l1.198,1.69l-1.298,1.094h-1.498l-4.144-0.795l-0.3,1.69l-3.145,0.398
                l-1.647-2.586l-1.947,0.398l-0.849,1.79l-2.996,0.547l-2.445,1.442l-0.95-1.193l-1.397,1.441l-1.648,0.598l0.599,2.138
                l-3.294,0.05l0.845,7.336l-0.072,4.139l1.57,4.995l2.854,1.998l1.285,4.138l1.426,1.855l7.421,0.856l3.711,2.569l0.285,2.282
                l3.283-2.854h2.284l-2.141,3.71l-2.568,2.997l-2.141,5.994l-12.844,26.972l-2.284,0.856l-0.713,2.284l1.999,3.282l0.142,1.57
                l0.427,1.57l0.857,0.429l-0.143,2.854l1.998,1.427c0,0,0.428,2.426,0.571,2.854c0.143,0.428,1.142,1.712,1.142,1.712l1.854,4.71
                l2.854,4.423l3.854,2.284l3.996,1.142l57.941,0.143l1.641-2.854l2.283-2.641l4.424-7.136l-1.641-3.853v-1.427l1.498-4.067
                l-1.141-1.855l-0.072-0.856l1.57-1.071l3.567-0.642l2.711-2.212l0.571-3.354v-4.067l-2.355-1.999l-1.854-2.925L228.32,92.976z"
                />

                <polygon
                  data-id="DF"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('DF')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                243.685,184.499 241.021,183.357 235.122,183.833 234.933,185.83 233.695,186.876 233.791,190.873 243.876,190.873
                243.781,187.447"
                />
              </g>

              <g>
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                211.845,27.945 212.012,28.675 212.343,28.741 212.776,29.967 213.642,30.1 214.606,28.807 213.575,26.619 213.275,26.619
                213.275,26.984 212.178,27.448       "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                212.012,26.885 212.144,27.282 213.008,26.917 212.976,26.52 212.109,26.222 211.645,26.719 211.744,26.885         "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                215.406,29.835 215.205,29.371 215.006,29.636 215.306,29.967         "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                216.454,36.738 216.121,36.738 215.688,37.07 215.654,37.435 216.221,37.269       "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                215.771,38.91 216.37,38.446 216.646,37.518 216.347,37.518 216.171,37.924 215.872,37.891 215.639,38.123      "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                219.797,46.913 218.986,47.552 219.118,47.907 219.527,48.369 220.146,48.546 222.279,48.12 222.987,47.09 222.442,46.241
                220.789,46.058      "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                197.483,60.614 197.934,60.963 199.23,60.714 199.729,60.316 199.13,59.919        "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                194.536,60.515 194.836,60.714 196.534,60.267 195.835,60.018         "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                190.943,61.809 191.667,61.51 191.692,61.013 190.843,61.51       "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                207.318,54.847 207.967,51.267 207.717,50.123 206.52,50.073 203.374,51.465 201.576,55.394 201.977,56.239 201.426,57.233
                199.929,57.78 199.629,58.924 199.23,59.72 200.229,60.316 203.974,57.432 204.073,56.886 205.87,55.891        "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                201.127,56.189 200.579,57.184 201.028,56.985 201.477,56.388         "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                205.071,57.582 205.722,56.836 204.272,57.432 204.123,58.079         "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                207.768,55.642 208.218,54.946 208.218,53.703 207.717,54.3 207.567,55.046 205.87,56.289 206.67,56.289        "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                208.641,55.816 209.739,55.369 209.59,55.12 207.793,55.816 207.394,56.612 208.291,56.413         "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                209.516,54.101 208.517,54.548 208.366,55.145 209.415,54.399         "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                210.913,52.062 210.612,52.311 210.289,51.813 209.04,52.808 208.666,53.305 208.517,54.349 210.963,52.858         "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                210.714,53.455 209.864,53.902 209.864,54.449 210.413,54.748 210.763,53.952      "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                210.763,55.394 210.863,54.896 210.512,55.096 209.964,55.096 210.264,55.493      "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                211.461,53.852 211.263,53.553 211.212,54.151        "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                208.417,51.565 208.167,52.56 208.167,53.106 208.517,52.51       "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                208.816,52.261 209.564,51.813 209.64,51.192 208.94,51.59        "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                208.268,50.869 208.017,50.272 208.066,50.869        "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                209.465,48.73 208.816,48.929 208.916,49.675 208.417,50.172 208.717,50.521 209.365,49.824 209.365,49.377 210.812,48.482
                210.063,48.382 209.664,47.935       "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                204.921,50.073 204.622,50.571 206.42,49.725 206.22,49.278       "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                207.268,47.687 206.568,48.73 206.568,49.079 209.116,47.438 209.116,46.245 207.816,46.891        "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                208.466,48.979 207.368,49.477 207.417,49.824 208.466,49.377         "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                211.212,48.283 210.014,47.835 210.313,48.134        "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                213.609,46.145 213.31,45.3 211.861,45.996 210.313,45.996 209.864,47.14 209.964,47.438 211.362,47.785 211.461,48.134
                212.012,47.487 213.209,47.09        "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                212.109,47.885 211.062,48.83 211.961,48.83      "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                211.212,49.178 210.362,49.228 209.116,50.919 209.964,51.018         "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                211.712,49.328 211.362,49.377 210.213,50.968 211.712,50.67      "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                212.46,49.775 211.961,50.073 212.012,50.471         "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                212.859,45.2 212.66,44.553 211.013,45.349 210.963,45.697 212.012,45.498         "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                217.203,45.051 216.604,44.454 215.406,44.553 215.306,45.846 215.555,46.394 217.752,46.344 217.902,46.891 218.427,47.164
                221.072,45.523 220.647,44.952 222.396,43.907 221.846,43.41 220.947,43.808 219.4,43.858 218.4,44.902         "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                214.906,46.145 214.508,45.598 213.958,45.647 213.908,46.245 214.558,46.741      "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                214.208,44.852 213.758,44.752 213.908,45.25 214.208,45.25       "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                215.106,44.852 214.407,44.752 214.707,45.2 215.056,45.151       "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                215.256,44.106 216.204,43.957 216.754,43.559 215.904,43.261 215.654,42.216 215.904,40.376 215.355,40.327 215.505,39.73
                214.657,40.128 214.558,41.471 214.757,42.664 214.058,43.062 213.858,44.255 215.106,44.553       "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                216.154,41.869 216.803,42.565 217.902,41.918 218.552,40.426 218.151,40.178 218.002,40.675 216.354,40.625        "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                217.802,43.559 217.004,44.057 217.401,44.206 218.701,43.907 218.851,42.962 218.102,42.664       "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                215.505,63.35 216.255,63.647 217.303,63.449 217.752,63.002 215.705,63.002       "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                222.894,65.563 223.393,64.866 223.243,64.568 222.894,65.164         "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                222.346,66.831 222.744,66.582 222.645,66.283 221.996,66.831         "
                />
                <polygon
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                227.736,60.068 227.937,60.068 228.186,59.67 227.837,59.62       "
                />

                <polygon
                  data-id="ES"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('ES')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                300.389,208.189 300.77,209.9 301.911,211.328 300.484,212.088 298.392,211.424 297.249,212.373 298.01,214.943 296.108,216.654
                296.772,218.844 298.486,220.461 298.866,223.6 296.012,225.979 295.061,230.164 291.35,230.832 290.685,230.928 290.874,235.969
                293.158,239.49 296.393,239.203 298.38,241.711 299.428,239.623 299.927,239.324 301.674,236.24 303.722,234.748 305.818,226.893
                307.066,225.549 309.163,224.854 311.061,222.318 310.517,213.857 305.525,209.9"
                />

                <polygon
                  data-id="SE"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('SE')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                338.778,139.356 335.496,136.218 332.641,136.218 330.357,132.65 324.364,131.508 325.648,134.363 324.078,136.075
                324.221,137.646 328.217,139.5 327.932,142.497 326.077,143.068 324.364,145.493 327.932,147.063 327.932,148.918
                330.563,148.894 331.096,148.761 331.296,149.292 331.896,148.098 331.762,147.501 331.962,146.838 331.362,146.375
                332.628,146.573 332.095,147.302 332.228,147.833 333.126,145.346 338.451,142.082 339.314,141.237"
                />

                <polygon
                  data-id="AL"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('AL')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                343.915,126.656 341.489,125.799 340.49,129.225 337.351,128.797 334.782,130.366 333.782,128.939 332.213,128.654
                332.213,127.37 330.644,126.228 329.073,127.37 327.646,125.372 326.647,127.655 323.079,128.797 324.364,131.508 330.357,132.65
                332.641,136.218 335.496,136.218 338.778,139.356 339.314,141.237 342.246,138.369 342.312,137.042 345.975,133.86
                345.975,132.866 346.44,133.396 347.238,132.666 346.905,132.07 347.505,132.467 348.57,130.479 351.398,125.805 347.197,124.516"
                />

                <polygon
                  data-id="RN"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('RN')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                328.073,101.539 330.785,99.113 333.64,99.684 332.784,101.824 331.356,101.824 329.645,104.965 331.356,104.821 331.499,106.677
                335.352,105.678 335.78,107.676 338.492,106.677 337.636,103.537 340.918,102.109 341.917,103.965 344.057,102.681
                347.911,103.965 351.648,103.03 350.3,99.351 350.15,99.202 350.55,98.555 349.7,95.423 347.754,93.533 343.96,92.539
                342.014,93.085 339.316,92.638 337.071,92.688 335.174,91.345 333.076,91.494 331.629,89.158 331.629,89.158 326.933,92.976
                324.649,97.686 321.795,98.971 319.654,101.967 320.082,103.965 322.08,102.681 323.937,103.823 326.79,100.541"
                />

                <polygon
                  data-id="PB"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('PB')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                326.361,113.241 328.502,112.956 329.787,111.101 334.496,111.386 334.496,112.813 332.213,113.099 333.068,115.097
                330.785,116.381 331.928,118.521 334.068,118.094 335.496,120.233 337.636,119.663 337.779,117.237 343.202,114.811
                344.057,115.953 345.627,115.239 345.057,114.098 346.627,112.956 349.053,113.527 350.337,109.959 353.19,108.817
                353.047,107.704 351.648,103.03 347.911,103.965 344.057,102.681 341.917,103.965 340.918,102.109 337.636,103.537
                338.492,106.677 335.78,107.676 335.352,105.678 331.499,106.677 331.356,104.821 329.645,104.965 331.356,101.824
                332.784,101.824 333.64,99.684 330.785,99.113 328.073,101.539 326.79,100.541 323.937,103.823 323.937,106.391 322.65,106.962
                320.938,106.677 319.654,107.391 318.799,106.534 317.655,107.961 317.799,108.959 319.939,109.103 319.226,110.958
                320.511,112.528 319.797,113.669 323.365,116.381"
                />

                <polygon
                  data-id="PE"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('PE')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                353.19,108.817 350.337,109.959 349.053,113.527 346.627,112.956 345.057,114.098 345.627,115.239 344.057,115.953
                343.202,114.811 337.779,117.237 337.636,119.663 335.496,120.233 334.068,118.094 331.928,118.521 330.785,116.381
                333.068,115.097 332.213,113.099 334.496,112.813 334.496,111.386 329.787,111.101 328.502,112.956 326.361,113.241
                323.365,116.381 319.797,113.669 317.799,113.527 315.23,116.238 311.521,111.814 309.38,111.671 307.81,110.815 306.383,111.957
                302.815,112.1 300.579,111.909 301.72,114.573 302.102,118.949 300.675,120.281 296.012,122.375 295.061,124.468 297.963,124.087
                299.818,125.799 299.104,128.083 301.531,128.654 301.815,130.366 303.527,131.081 305.812,130.794 308.095,128.511
                308.809,126.085 310.521,125.799 312.519,124.23 315.372,123.944 317.514,125.799 319.226,125.799 319.654,127.227
                321.653,127.37 323.079,128.797 326.647,127.655 327.646,125.372 329.073,127.37 330.644,126.228 332.213,127.37 332.213,128.654
                333.782,128.939 334.782,130.366 337.351,128.797 340.49,129.225 341.489,125.799 343.915,126.656 347.197,124.516
                351.398,125.805 353.296,118.396 353.845,112.528"
                />

                <polygon
                  data-id="RO"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('RO')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                124.569,162.477 127.71,161.478 127.138,158.908 128.852,157.48 126.139,151.773 127.71,149.204 126.853,145.637 122.429,144.78
                117.862,145.493 116.579,143.21 113.867,142.354 113.438,138.073 115.008,132.364 113.438,129.082 114.58,126.371
                112.012,124.087 108.158,124.087 103.734,121.661 101.308,117.666 96.599,117.666 93.744,119.521 91.604,122.232 91.604,124.944
                90.605,126.514 87.894,126.942 84.753,128.797 85.039,132.792 83.469,134.363 81.757,134.363 79.532,137.421 79.712,137.44
                79.513,144.699 82.708,152.258 86.102,153.949 89.098,157.032 95.289,157.23 97.686,159.22 100.78,158.922 104.176,162.602
                107.571,163.894 110.065,166.58 117.456,166.679 122.681,169.708 125.141,166.329"
                />

                <polygon
                  data-id="AP"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('AP')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                174.328,33.609 182.51,36.083 183.271,39.508 185.745,41.22 187.267,42.172 187.647,46.548 190.692,50.164 193.736,53.398
                194.879,54.349 195.259,55.682 194.497,57.014 195.068,58.346 197.635,58.516 199.081,57.73 200.928,54.051 201.527,53.852
                202.675,51.217 206.07,48.879 207.567,46.642 210.213,44.852 211.413,42.813 214.208,40.426 214.258,39.681 215.406,38.288
                215.456,36.548 216.006,36.1 216.306,36.2 217.252,35.255 217.004,34.808 215.805,35.404 215.256,35.105 214.807,34.459
                214.208,34.957 214.157,35.305 213.159,35.603 213.908,35.205 213.758,34.907 214.407,34.26 214.957,34.211 215.456,34.957
                215.955,35.105 216.854,34.658 216.954,33.763 216.754,32.918 217.054,31.674 216.405,31.028 214.108,30.432 212.809,30.68
                211.761,30.183 211.812,29.586 211.062,28.542 211.162,27.896 210.464,27.796 210.812,27.448 210.313,26.006 209.614,23.371
                207.917,18.398 207.368,13.375 207.118,12.829 206.67,13.077 206.42,14.62 206.37,12.382 205.172,9.796 203.125,7.906
                202.675,8.702 202.626,10.194 202.275,9.249 201.627,10.741 199.879,11.487 199.03,12.232 196.234,15.315 194.236,19.79
                192.791,24.565 188.497,28.741 184.203,28.691 180.808,30.68 176.266,29.835 174.268,26.702 173.168,25.559 173.377,30.375"
                />

                <polygon
                  data-id="AC"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('AC')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                34.662,120.662 27.527,118.949 16.253,117.808 9.973,116.096 2.199,113.858 0.134,114.5 0,116.356 1.532,117.948 2.131,120.037
                6.224,124.611 8.221,126.103 8.521,128.191 9.22,129.882 7.523,133.064 8.521,134.258 13.413,133.761 14.313,135.054
                17.707,138.932 21.701,138.833 25.695,137.042 26.294,134.457 27.792,133.263 29.688,130.479 30.887,130.479 29.789,134.357
                31.187,135.749 31.985,142.015 33.882,144.203 38.176,141.816 41.87,142.412 45.964,142.313 50.357,146.192 52.354,146.391
                54.151,142.114 56.848,140.225 63.837,140.026 70.312,136.403 51.502,129.225"
                />

                <polygon
                  data-id="CE"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('CE')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                329.781,88.412 328.383,88.61 327.285,87.666 326.786,86.124 326.187,85.428 324.589,84.632 321.743,81.549 317.051,78.366
                313.706,75.582 312.009,75.333 307.715,72.449 305.469,71.902 301.823,72.647 295.833,73.344 294.3,76.422 294.585,78.516
                296.772,80.132 297.629,84.89 296.012,87.649 296.679,91.55 298.392,93.167 297.724,94.308 297.915,96.401 299.247,97.543
                298.961,100.017 299.818,105.345 302.481,105.345 303.146,108.199 302.006,110.577 300.579,111.909 302.815,112.1
                306.383,111.957 307.81,110.815 309.38,111.671 311.521,111.814 315.23,116.238 317.799,113.527 319.797,113.669 320.511,112.528
                319.226,110.958 319.939,109.103 317.799,108.959 317.655,107.961 318.799,106.534 319.654,107.391 320.938,106.677
                322.65,106.962 323.937,106.391 323.937,103.823 322.08,102.681 320.082,103.965 319.654,101.967 321.795,98.971 324.649,97.686
                326.933,92.976 331.629,89.158"
                />

                <polygon
                  data-id="RR"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('RR')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                101.308,34.037 104.162,37.748 104.447,44.455 107.017,50.877 105.874,53.018 112.725,58.869 113.01,54.445 116.721,50.734
                119.146,54.017 123.999,53.446 123.428,49.878 126.71,48.451 128.28,44.027 137.771,43.884 136.926,36.548 135.578,34.857
                133.78,34.559 129.487,30.133 130.235,27.249 129.586,25.757 126.841,24.316 126.541,21.481 128.238,18.398 128.487,14.47
                130.734,12.68 130.734,10.393 128.887,7.906 126.591,7.409 125.692,6.017 126.441,4.077 126.641,1.193 125.093,0.448
                123.845,0.796 121.848,0 120.851,0.53 120.117,4.044 113.528,6.563 113.594,8.553 108.069,9.746 103.743,9.878 100.281,12.663
                97.751,13.393 95.821,12.531 93.292,12.663 90.097,9.812 86.569,8.751 84.372,11.801 86.569,11.205 89.231,12.597 90.03,15.646
                89.032,16.973 88.366,20.155 88.499,24.597 91.162,26.52 94.755,25.857 96.553,26.52 95.782,28.182 99.596,29.613"
                />

                <polygon
                  data-id="AM"
                  onClick={handleOnClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  fill={getStateColor('AM')}
                  stroke={strokeColor}
                  strokeLinecap={strokeLinecap}
                  strokeLinejoin={strokeLinejoin}
                  strokeMiterlimit={strokeMiterlimit}
                  points="
                159.534,63.864 156.251,66.717 155.966,64.435 152.255,61.866 144.834,61.009 143.408,59.154 142.123,55.016 139.269,53.018
                137.699,48.023 137.771,43.884 128.28,44.027 126.71,48.451 123.428,49.878 123.999,53.446 119.146,54.017 116.721,50.734
                113.01,54.445 112.725,58.869 105.874,53.018 107.017,50.877 104.447,44.455 104.162,37.748 101.308,34.037 99.596,29.613
                95.782,28.182 93.558,27.979 92.359,29.04 89.831,29.901 89.763,32.354 87.301,34.808 86.17,33.349 83.706,35.47 77.715,36.929
                75.785,39.78 73.521,39.382 71.991,39.515 70.859,40.178 68.862,39.581 65.134,36.2 63.337,37.692 62.139,36.797 62.339,32.719
                60.242,29.437 58.046,29.337 57.247,32.321 55.15,32.918 53.153,29.239 51.556,29.337 51.056,31.426 39.874,31.625 38.575,34.211
                39.874,36.896 43.668,37.095 44.566,39.482 43.668,42.267 39.973,40.775 37.477,42.764 37.078,50.023 40.372,53.206
                40.172,56.289 43.168,58.676 38.376,87.118 34.382,85.825 28.59,85.229 27.592,87.218 20.702,88.212 12.082,92.729 10.618,94.851
                9.52,99.251 7.789,102.235 8.388,103.826 7.723,105.484 3.528,107.937 2.197,111.915 2.199,113.858 9.973,116.096 16.253,117.808
                27.527,118.949 34.662,120.662 51.502,129.225 70.312,136.403 79.532,137.421 81.757,134.363 83.469,134.363 85.039,132.792
                84.753,128.797 87.894,126.942 90.605,126.514 91.604,124.944 91.604,122.232 93.744,119.521 96.599,117.666 101.308,117.666
                103.734,121.661 108.158,124.087 112.012,124.087 114.58,126.371 140.649,125.419 141.314,123.136 142.647,121.042
                141.885,117.998 142.409,116.381 142.551,113.527 141.694,113.099 141.267,111.529 141.125,109.959 139.126,106.677
                139.839,104.393 142.123,103.537 154.967,76.565 157.108,70.571 159.676,67.574 161.817,63.864"
                />
              </g>
                </g>

            </svg>
          </Box>
          <Box>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  {lastID === null ? 'Informações gerais' : 'Informações por estado: ' + lastID}
                </Typography>
                <Typography variant="body2" component="div">
                  {lastID === null ? (
                    <Box>
                      {!noTotal && (
                        <Box>Valor total: {formatAsCurrency ? formatCurrency(total) : total}</Box>
                      )}
                      <Box>
                        Valor conquistado {formatAsCurrency ? formatCurrency(value) : value}
                      </Box>
                      {!noTotal && (
                        <Box>Valor restante: {formatAsCurrency ? formatCurrency((total-value)) : (total - value)}</Box>
                      )}
                      {/* <br/><br/>Valores por estado: */}
                      {/* {mapData.map((item, index) => (
                        <Box>
                          <br/>Estado: {item.nome}
                          <br/>Valor total: {item[totalField]}
                          <br/>Valor conquistado: {item[sumField]}
                          <br/>Valor restante: {item[totalField] - item[sumField]}
                          <br/><br/>
                          {selectedShowDetailed && (
                            <Box>
                              <br/>Valores por vendedor:
                              {item[detailedField].map((item, index) => (
                                <Box>
                                  <br/>Vendedor: {item.nome}
                                  <br/>Valor total: {item[totalField]}
                                  <br/>Valor conquistado: {item[sumField]}
                                  <br/>Valor restante: {item[totalField] - item[sumField]}
                                  <br/><br/>
                                </Box>
                              ))}
                            </Box>
                          )}
                        </Box>
                      ))} */}
                    </Box>
                  ):(
                    <Box>
                      {!noTotal && (
                        <Box>
                          Valor total: {formatAsCurrency ? formatCurrency(data.filter(item => item.nome === lastID)[0][totalField]) : data.filter(item => item.nome === lastID)[0][totalField]}
                        </Box>
                      )}
                      <Box>
                        Valor conquistado: {formatAsCurrency ? formatCurrency(data.filter(item => item.nome === lastID)[0] ? data.filter(item => item.nome === lastID)[0][sumField] : 0) : data.filter(item => item.nome === lastID)[0] ? data.filter(item => item.nome === lastID)[0][sumField] : 0}
                      </Box>
                      {!noTotal && (
                        <Box>
                          Valor restante: {formatAsCurrency ? formatCurrency((data.filter(item => item.nome === lastID)[0][totalField] - data.filter(item => item.nome === lastID)[0][sumField])) : (data.filter(item => item.nome === lastID)[0][totalField] - data.filter(item => item.nome === lastID)[0][sumField])}
                        </Box>
                      )}
                      {selectedShowDetailed && (
                        <Box>
                            {mapData.filter(item => item.nome === lastID)[0][detailedField].map((item, index) => (
                              <Card variant="outlined" key={item.nome}>
                                <CardContent>
                                  <Typography align='center' alignContent='center'>
                                    {item.nome}
                                  </Typography>
                                  {!noTotal && (
                                    <Typography align='center' alignContent='center'>
                                      Valor total: {formatAsCurrency ? formatCurrency(item[totalField]) : item[totalField]}
                                    </Typography>
                                  )}
                                  <Typography align='center' alignContent='center'>
                                    Valor conquistado: {formatAsCurrency ? formatCurrency(item[sumField]) : item[sumField]}
                                  </Typography>
                                  
                                  {!noTotal && (
                                    <Typography align='center' alignContent='center'>
                                      Valor restante: {formatAsCurrency ? formatCurrency(item[totalField] - item[sumField]) : item[totalField] - item[sumField]}
                                    </Typography>
                                  )}
                                </CardContent>
                              </Card>
                            ))}
                        </Box>
                      )}
                    </Box>
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    );
}

Brasil.defaultProps = {
    data: [],
    sumField: 'value',
    totalField: 'total',
    total: 0,
    value: 0,
    noTotal: false,
    formatAsCurrency: false,
    selectedShowDetailed: false,
    detailedField: 'vendedores',
    bgFillColor: '#EFEFEF',
    mapWidth: 500,
    mapHeight: 500,
    selectedColor: 'rgb(0, 255, 0)', // green
    strokeColor: '#FFFFFF',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
};

export default Brasil