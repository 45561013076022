import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';

function PieChart({ title, data, labels, id }) {
    useEffect(() => {
        const ctx = document.getElementById(id);
        if (!ctx) {
            return;
        }

        const myChart = new Chart(ctx, {
            type: 'pie',
            data: {
                labels: labels,
                datasets: [{
                    data: data,
                    backgroundColor: [
                        'red',
                        'blue',
                        'green',
                        'yellow',
                        'orange',
                    ],
                }]
            },
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: title,
                        padding: {
                            top: 10,
                            bottom: 30
                        },
                        font: {
                            size: 16
                        }
                    },
                    legend: {
                        position: 'right',
                        labels: {
                            boxWidth: 20
                        }
                    }
                }
            }
        });

        if (ctx && ctx.style) {
            ctx.style.backgroundColor = 'white';
        }

        return () => {
            myChart.destroy();
        };
    }, [title, data, labels, id]);

    return (
        <canvas id={id} />
    );
}

export default PieChart;
