import { Box, Typography } from '@mui/material';
import NovosClientesList from '../../components/Vendedor/NovosClientesList';

const VendedorNovosClientes = () => {

    return (
        <Box>
            <Typography align="center" spacing={2} component='h1' variant="h2">
                Novos Clientes
            </Typography>
            <NovosClientesList />
        </Box>
    )

}

export default VendedorNovosClientes;