import { Box, Typography } from "@mui/material";
import ClientesList from "../../components/Vendedor/ClientesList";

const VendedorClientes = () => {

    return (
        <Box>
            <Typography variant="h4" component="h1" gutterBottom textAlign='center' mt={2}>
                Clientes
            </Typography>

            <Box>
                <ClientesList />
            </Box>
        </Box>
    )

}

export default VendedorClientes;