import Header from "../components/Header";
import Footer from "../components/Footer";
import { Box, Container, Typography, Grid } from "@mui/material";
import WidgetVendas from "../components/Vendas/WidgetVendas";

const Vendas = () => {

    return (
        <Container component="main" disableGutters>
            <Header/>
                <Container component="main" maxWidth="lg" >
                    <Box>
                        <Typography align="center" spacing={2} component='h1' variant="h2">
                            Vendas
                        </Typography>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item sx={ {width: '100%'} }>
                                <WidgetVendas/>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            <Footer/>
        </Container>
    );
};

export default Vendas;