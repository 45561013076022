import axios from 'axios';

const HttpService = () => {

    const api = process.env.REACT_APP_SERVER;

    const axiosApi = axios.create({
        baseURL: api,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });

    const get = (url, params = {}) => {
        return axiosApi.get(url, params);
    };

    const post = (url, data) => {
        return axiosApi.post(url, data);
    };

    const put = (url, data) => {
        return axiosApi.put(url, data);
    };

    const remove = (url) => {
        return axiosApi.delete(url);
    };

    return {
        get,
        post,
        put,
        remove
    };

};

export default HttpService;