import HttpService from "./HttpService";

const MetasService = () => {
  const get = () => {
    return HttpService().get(`resource/vendedor/metas`);
  };

  const post = (data) => {
    return HttpService().post(`resource/vendedor/metas`, data);
  };

  const remove = (id) => {
    return HttpService().remove('resource/vendedor/metas/' + id);
  }

  return {
    get,
    post,
    remove,
  };
};

export default MetasService;