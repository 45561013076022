//Verifica o contexto de usuário para saber qual Header retornar, se o usuário estiver logado, retorna o Header de usuário logado, se não, retorna o Header de usuário não logado
import { useAuth } from '../../context/AuthContext';
import HeaderLoggedAdmin from './HeaderLoggedAdmin';
import HeaderLoggedVendedor from './HeaderLoggedVendedor';
import HeaderNotLogged from './HeaderNotLogged';

const Header = () => {
    const { user } = useAuth();

    if (user && user.user.role === 1) {
        return (<header><HeaderLoggedAdmin /></header>);
    }
    if (user && user.user.role === 0) {
        return (<header><HeaderLoggedVendedor /></header>);
    }

    return (<header><HeaderNotLogged /></header>);

};

export default Header;